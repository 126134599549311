import React from 'react';
import { Grid, Paper, Typography, Tooltip, IconButton, Alert, AlertTitle, Divider } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { LoginStyles } from '../../../customStyles';
import { AlertStyles } from "../../../customStyles/common";
import { DividerStyle } from "../../../customStyles/common";

interface SetUpMFAComponentProps {
  authCode: {
    auth?: string;
    text?: string;
  };
  copyText: () => void;
  copySuccess: string;
}

export const SetUpMFAComponent: React.FC<SetUpMFAComponentProps> = ({ authCode, copyText, copySuccess }) => {
  return (
    <>
      <Grid container display='flex' justifyContent='center' alignItems='center'>
        <Paper sx={LoginStyles.qrCodeStyle}>
          <QRCodeSVG
            size={175}
            value={
              authCode.auth
                ? authCode.auth
                : `otpauth://totp/AWSCognito:" + user.username + "?secret=" + res + "&issuer=Cognito`
            }
          />
        </Paper>
      </Grid>
      <span style={LoginStyles.authCodeContainerStyle}>
        <span style={LoginStyles.authCodeTextStyle as React.CSSProperties}>
          <Typography sx={LoginStyles.qrCodeVerificationStyle}>
            {authCode.text}
          </Typography>
        </span>
        <Tooltip title="Copy">
          <IconButton aria-label="copy" sx={{ color: "#A1A1A5", marginLeft: '0.5rem' }} onClick={copyText}>
            <CopyAllIcon />
          </IconButton>
        </Tooltip>
        <p style={LoginStyles.copySuccessStyle}>
          {copySuccess}
        </p>
      </span>
      <Alert severity='info' sx={{ ...AlertStyles.info, mb: '0.5rem' }}>
        <AlertTitle color='info.contrastText'>
          Save the setup key or QR code for recovery.
        </AlertTitle>
        <Typography variant="body2" color='info.contrastText'>
          Use the setup key or QR code to reset MFA access. Do not share this setup key or QR code with anyone
        </Typography>
      </Alert>
      <Divider style={DividerStyle} />
      <Grid container display='flex' justifyContent='center'>
        <Typography variant='subtitle1'>Enter verification code</Typography>
      </Grid>
    </>
  );
};