import dayjs from "dayjs";
import { extractPageName } from "../common/CustomTable/helpers";
import { PageNames } from "interfaces/config";
let utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const getValue = (value: string) => {
    console.log(`101010101 debug getParamValues getValue value ${value}`)
    if (value === "") {
        return "null"
    } else return value
}

const mapCHRISearchTerm = (searchTerm: string) => {
    let mapY = searchTerm.toLowerCase() === "found" || searchTerm.toLowerCase() === "record found"
    let mapN = searchTerm.toLowerCase().includes("no")
    let mapNull = searchTerm.toLowerCase().includes("pending") || searchTerm.toLowerCase().includes("results")
    let mapNS = searchTerm.toLowerCase().includes("name") || searchTerm.toLowerCase().includes("search")
    let mapNA = searchTerm.toLowerCase().includes("n/") 

    if (mapY) return "recordFound"
    if (mapN) return "noRecordFound"
    if (mapNull) return "resultsPending"
    if (mapNS) return "nameSearch"
    if (mapNA) return "N/A"
}


export const getAdvancedParamValues = (column: any, searchParams: any, dateBound: any = undefined, pageName = "") => {
    let testColumn = "dob"
    //console.log('debug getAdvancedParamValues column: ', { column, searchParams, dateBound, pageName})
    if (column === testColumn) console.log('debug getAdvanceParamValues: ', {column, searchParams, dateBound, pageName})
   
    if (searchParams.find(((param: any) => param.column === column))) {
        /*if(pageName === PageNames.TRANSACTION_SUMMARY && column === "dob") {
            let value = getValue(searchParams.find((param) => param.column === column).value)
            return dayjs(value).format('YYYYMMDD')
        }*/
        if (!dateBound) {
            if (column === testColumn) console.log('debug getAdvanceParamValues !dateBound ')
            let value = getValue(searchParams.find((param) => param.column === column).value)

            if (column === "upperDate") {
                return dayjs(value).endOf("day").utc().format(); // "2023-12-30T01:29:36.276981+00:00"
            } else if (column === "lowerDate") {
                return dayjs(value).startOf('day').utc().format(); // "2023-12-30T01:18:38.601648+00:00" 
            } else {
                if (column === "hitNoHit") return mapCHRISearchTerm(value)
                else return value.trim()
            }
        } else {
            //simple search date
            const date: string = searchParams.find((param) => param.column === column).value
            console.log('debug getAdvancedParamValues date: ', date)
            if (column === testColumn) console.log('debug getAdvanceParamValues dateBound date:  ', { dateBound, date})

            if (!date.includes("-")) {
                console.log('debug getAdvancedParamValues: ', {dateBound, pageName})

                if (dateBound === "lowerDate") {

                    console.log('debug getAdvanceParamValues: ', {column, searchParams, dateBound, pageName})
                    return pageName === PageNames.TRANSACTION_SUMMARY && column !== "dob" ? dayjs(date).startOf("day").utc().format() : dayjs(date).startOf("day").format('YYYYMMDD')
                } else {
                    return pageName === PageNames.TRANSACTION_SUMMARY && column !== "dob" ? dayjs(date).endOf("day").utc().format() : dayjs(date).startOf("day").format('YYYYMMDD')
                } 
            } else {
                let parseWithSpace = date.includes(" - ")
                let parsedDate = date.split(parseWithSpace ? " - " : "-");
                let lowerDate = parsedDate[0]
                let upperDate = parsedDate[1]
                console.log('debug getAdvancedParamValues: ', {dateBound, pageName})
                if (dateBound === "lowerDate") {
                    if (column === testColumn) console.log('debug getAdvanceParamValues returning:  ', pageName === PageNames.TRANSACTION_SUMMARY ? dayjs(lowerDate).startOf('day').utc().format() : dayjs(lowerDate).startOf('day').format('YYYYMMDD') )
                    return pageName === PageNames.TRANSACTION_SUMMARY && column !== "dob" ? dayjs(lowerDate).startOf('day').utc().format() : dayjs(lowerDate).startOf('day').format('YYYYMMDD') ;
                } else {
                    if (column === testColumn) console.log('debug getAdvanceParamValues returning:  ', pageName === PageNames.TRANSACTION_SUMMARY ? dayjs(upperDate).endOf("day").utc().format() : dayjs(upperDate).endOf("day").format('YYYYMMDD') )

                    return pageName === PageNames.TRANSACTION_SUMMARY && column !== "dob" ? dayjs(upperDate).endOf("day").utc().format() : dayjs(upperDate).endOf("day").format('YYYYMMDD') ;
                }
            }
        }

    } else {
        
        return 'null'
    }
}

export const getBatchPrintParamValues = () => {

}

export const getBasicParamValues = (column: any, searchParams) => {
    if (searchParams.find((param: any) => param.column === column)) {
        let value = searchParams.find((param) => param.column === column).value
        return value;
    } else {
        return 'null';
    }
}

export const getRapBackParamValues = (column: any, searchParams, dateBound: any = undefined) => {
    searchParams.find((param: any) => console.log(`getRapbackParamValues types: ${ JSON.stringify({searchParams, paramColumnType: typeof param.colum, columnType: typeof column}) } paramColumn ${param.column} === ${column}: ${param.column == column }`))
    if (searchParams.find((param: any) => param.column === column)) {
        console.log('getRapbackParamValues args: ', { column, searchParams, dateBound, condition: searchParams.find((param: any) => param.column === column) })
        if (!dateBound) {
            // when it's not a date search
            let value = searchParams.find((param) => param.column === column).value
            if (column === 'isHit') {
                if (value.toLowerCase() === 'no hit') {
                    return 'n'
                } else if (value.toLowerCase() === 'hit') {
                    return 'y'
                } else if (value.toLowerCase() === 'ERRT-Error') {
                    return 'errt'
                }
            } else {
                console.log("getRapbackParamValues return value: ", value)
                return value;
            }
        } else if (column === 'dob') {
            return dayjs(searchParams.find((param) => param.column === column).value).format("MMDDYYYY");
        } else {
            const date: string = searchParams.find((param) => param.column === column).value
            if (!date.includes("-")) {
                console.log("Custom table search date is singular date")
                if (dateBound === "lowerDate") {
                    console.log('dayjs(lowerDate).format(YYYYMMDD);', dayjs(date).format('YYYYY-MM-DDTHH:mm:ss.SSSSSZ'))

                    return dayjs(date).startOf('day').format('YYYYMMDD');
                } else {
                    // return dayjs(date).add(1, "day").format('YYYYMMDD')
                    return dayjs(date).endOf("day").format('YYYYMMDD');
                }
            } else {
                console.log('Custom table search date is a range');
                let parseWithSpace = date.includes(" - ")
                let parsedDate = date.split(parseWithSpace ? " - " : "-");
                let lowerDate = parsedDate[0]
                let upperDate = parsedDate[1]
                console.log('custom table search parsedDate: ', parsedDate)
                if (dateBound === "lowerDate") {
                    return dayjs(lowerDate).startOf('day').format('YYYYMMDD');
                } else {
                    return dayjs(upperDate).endOf("day").format('YYYYMMDD');
                }
            }
        }
    } else {
        return 'null'
    }
}