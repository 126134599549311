import { create } from "zustand"

type ProgressVariant = "determinate" | "indeterminate" | "buffer" | "query" | undefined
interface CustomModalState {
    dialogStyles: any,
    openCustomModal: boolean,
    customModalTitle: string,
    contentText: string, 
    contentStyle: any,
    progressVariant?:  ProgressVariant,
    modalActionsConfig?: any,
    setDialogStyles: (dialogStyles: any) => void,
    setOpenCustomModal: (openCustomModal: boolean) => void,
    setCustomModalTitle: (customModalTitle: string) => void,
    setContentText: (contentText: string) => void, 
    setContentStyle: (contentStyle: any) => void,
    setProgressVariant: (progressVariant: ProgressVariant) => void
    setModalActionsConfig: (modalActionsConfig: any) => void
}

export const useCustomModalStore = create<CustomModalState>((set) => ({
    dialogStyles: {},
    openCustomModal: false,
    customModalTitle: "",
    contentText: "" ,
    contentStyle: {},
    progressVariant: undefined,
    setDialogStyles: (dialogStyles: any) => set({ dialogStyles }),
    setOpenCustomModal: (openCustomModal: boolean) => set({ openCustomModal }),
    setCustomModalTitle: (customModalTitle: string) => set({ customModalTitle }),
    setContentText: (contentText: string) => set({ contentText }), 
    setContentStyle: (contentStyle: any) => set({ contentStyle }),
    setProgressVariant: (progressVariant: ProgressVariant) => set({ progressVariant }),
    setModalActionsConfig: (modalActionsConfig: any) => set({modalActionsConfig})

}))

