
/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import React, { useEffect } from "react";
import { urls } from '../urls'
import { useUserStore } from "state";
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";

export const useExpectedResponse = (registrationId: string, resultRespData?: any[]) => {
    const [expectedResponse, setExpectedResponse] = React.useState<number>();
    const [receivedResponses, setReceivedResponses] = React.useState<number>(0);
    const config = useUserStore(state => state.config);
    const { nistParsing } = config;

    const agencyIdentifier = nistParsing.fields.find(item => item.name === "agencyIdentifier");

    const updatedAgencyIdentifier = agencyIdentifier
      ? { ...agencyIdentifier, key: `type${agencyIdentifier.type}${capitalizeFirstLetter(agencyIdentifier.key)}`}
      : null; 

    const reportingUrl = urls.REPORTING;
    let receivedResponseTypes: string[] = [];

    useEffect(() => {
        const controller = new AbortController();
        const { signal } = controller;
        let fetchReportingsUrl = `${process.env.NODE_ENV === 'production' ? reportingUrl : ""}/adjudication/transactions/expectedResponses/${registrationId}`;
        
        const fetchUrls = async () => {
            try {
                const { resp } = await (await fetch(fetchReportingsUrl, {
                    credentials: "include",
                    signal
                })).json();
                if (resp && resultRespData?.length && updatedAgencyIdentifier) {
                    resp.map((responseType: string) => {
                        if (resultRespData.filter(response => responseType?.toLowerCase() === response[updatedAgencyIdentifier.key]?.toLowerCase())?.length && !receivedResponseTypes.includes(responseType)) {
                            receivedResponseTypes.push(responseType);
                        }
                    })
                }
                setExpectedResponse(resp?.length);
                setReceivedResponses(receivedResponseTypes.length);
            } catch (err: any) {
                console.log('use expected response error: ', err);
            }
        }
        
        fetchUrls();
        
        const refreshUrlInterval = setInterval(() => {
            fetchUrls();
        },60000);

        return () => {
            clearInterval(refreshUrlInterval);
            controller.abort();
        }
    }, [registrationId, resultRespData, reportingUrl])

    return { expectedResponse, receivedResponses };
};
