import { Button, FormControl, InputLabel, MenuItem, Paper, Radio, Select, Stack, Typography } from "@mui/material"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from "react"
import { CustomFilter } from "./CustomFilter";
import { useTableStore } from "state/TableStore";
import { UserRow } from '../../../interfaces/hooks'
import { useUserManagementStore } from "pages/UserManagement/state";
import { Components } from '../../../state/TableStore'
import dayjs from 'dayjs';


interface CustomSelectItem {
    value: string
    label: string
}
interface CellSelectProps {
    rowData: UserRow
    menuItems: CustomSelectItem[]
    dataIndex: string
    value: string
    tableName: string
}

export const CustomTableCellSelect: React.FC<CellSelectProps> = ({ rowData, menuItems, dataIndex, value, tableName }) => {
    // local states
    const [date, setDate] = useState(rowData.lastModified)

    // table store
    const customFilterAnchorEl = useTableStore(state => state.customFilterAnchorEl)
    const setCustomFilterAnchorEl = useTableStore(state => state.setCustomFilterAnchorEl)
    const currentCell = useTableStore(state => state.currentCell)
    const setCurrentCell = useTableStore(state => state.setCurrentCell)
    const setComponent = useTableStore((state) => state.setComponent)
    const component = useTableStore((state) => state.componentConfig)
    const setCurrentDataIndex = useTableStore(state => state.setCurrentDataIndex)

    // user management store
    const setSelectedRow = useUserManagementStore((state) => state.setSelectedRow)

    const handleOpen = (e: any, row: any, dataIndex: string) => {
        e.stopPropagation()
        setCustomFilterAnchorEl(e.currentTarget)
        setCurrentCell?.(row.loginName)
        setSelectedRow(row)
        setCurrentDataIndex(dataIndex)
        setComponent(
            {
                ...component,
                [Components.CUSTOM_FILTER]: {
                    ...component[Components.CUSTOM_FILTER],
                    selectedValue: value
                }
            }
        )
    }

    useEffect(() => {
        setDate(rowData.lastModified)
    }, [rowData])

    return (
        <Stack sx={{ minWidth: "100%" }} direction="column">
            <Button
                onClick={(e) => {
                    e.stopPropagation()
                    handleOpen(e, rowData, dataIndex)

                }} color="primary" endIcon={<ArrowDropDownIcon />} size='small'
                sx={{ width: dataIndex === 'status' ? '85px' : '157px' }}>
                <Typography sx={{ fontSize: '13px', fontWeight: 500 }}>
                    {value}
                </Typography>
            </Button>
            {dataIndex === 'status' &&
                (<Typography sx={{ display: 'flex', justifyContent: 'flex-start', fontSize: '14px' }} >
                    {date}
                </Typography>)}
            <CustomFilter
                tableName={tableName}
                data={rowData}
                dataIndex={dataIndex}
                tableFilter={false}
                enableHeaderButton={false}
                enableFooterButtons={false}
                radio={true} // need to pass down from cell 
                cell={rowData?.loginName}
                currentCell={currentCell}
                setCurrentCell={setCurrentCell}
                menuItems={menuItems}
                customFilterAnchorEl={customFilterAnchorEl}
                setCustomFilterAnchorEl={setCustomFilterAnchorEl} />
        </Stack>
    )
}