/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from "react";
import printText from "utils/printText";
import NotesService from "utils/notesService";
import { useParams } from "react-router-dom";
import { disableInputForNonEdit } from "utils/restrictionAccess";
import { useAudit } from '../../../hooks/useAudit'
import { Auth } from 'aws-amplify';
import { urls } from "urls";
import he from 'he';
import { formatDate } from "utils/formatDate";
import { NotesListProps } from "@propTypes/index";
import { DeleteModal } from './DeleteModal';
import { NoteCard } from './NoteCard';

const convertName = (name: string) => {
  if (!name) return "";
  const parts = name.split(".");
  if (parts.length !== 2) return name;
  return `${parts[0].charAt(0).toUpperCase()}${parts[0].slice(1)} ${parts[1]
    .charAt(0)
    .toUpperCase()}${parts[1].slice(1)}`;
};

export const NotesList: React.FC<NotesListProps> = ({
  personalInfo,
  userRole,
  notes,
  setNotes,
  selectedNoteIdState,
  blankNoteState,
  currentUser,
  usersPermissions,
  view
}) => {

  const noteAuthorName = `${personalInfo["firstName"]} ${personalInfo["middleName"]} ${personalInfo["lastName"]}`
  const [showEdits, setShowEdits] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [updatedNotes, setUpdatedNotes] = useState('');
  const { registrationId = "" } = useParams<{ registrationId: string }>();
  const url = urls.NOTES
  let notesService = new NotesService(registrationId, 'note', url);
  const [hasBlankNote, setHasBlankNote] = blankNoteState;
  const [selectedNoteId, handleSelectedNoteChange] = selectedNoteIdState;
  const { addAuditEvent } = useAudit()

  useEffect(() => {
    notesService = new NotesService(registrationId, 'note', url);
  }, [url])

  const [activeSection, setActiveSection] = useState<string>('');
  const [showEditsById, setShowEditsById] = useState<string>('');

  const editNotes = (id: string) => {
    setActiveSection(id);
  }

  const showPreviousNotes = ((id: string) => {
    setShowEditsById(id)
    setShowEdits(!showEdits)
  })

  const confirmDeleteNote = async (id: string) => {
    // Not deleting notes at the moment for Maine & New Mexico. 
    // Left this in case other clients want this feature.
    const { username } = await Auth.currentAuthenticatedUser()
    setNotes((currentNotes: any) =>
      currentNotes.filter((note: any) =>
        note.temporaryId ? note.temporaryId !== selectedNoteId : note.id !== selectedNoteId
      )
    );
    setOpen(false);
    handleSelectedNoteChange('');
    if (id === selectedNote?.["temporaryId"]) {
      setHasBlankNote(false);
      return;
    };
    notesService.deleteNote(id).then(() => {
      addAuditEvent("Deleted", "Notes / Existing Note", new Date(), registrationId)
    });
  };

  const printNote = (note: object) => {
    printText(
      note?.["body"],
      convertName(note?.["creator"]),
      `${convertName(note?.["creator"])} - ${formatDate(note?.["datelastmodified"])}`, 
      undefined,
      usersPermissions["editOwnNotes"] ? note?.["attachments"] : []
      );

      addAuditEvent('Created', "Notes / Existing Note", new Date(), registrationId)
    };

  console.log('debug notes notesList userRole: ', userRole)
  console.log(`debug notes notesList hasblankNote: ${hasBlankNote} || disableInputForNonEdit(${userRole}): `, hasBlankNote || disableInputForNonEdit(userRole))

  const selectedNote = notes?.find((note: any) =>
    note.temporaryId ? note.temporaryId === selectedNoteId : note.id === selectedNoteId
  );

  const encodeText = (text: string) => {
    const sanitizedInput = text.replace(/[&<>"']/g, '');
    const encodedText = he.encode(sanitizedInput);
    return encodedText;
  };

  const updateCurrentNotes = (e: any) => {
    setUpdatedNotes(e.target.value);
  }

  const updateSelectedNote = async (newNoteText: any, id: any) => {
    let previousVersions;
    setNotes((currentNotes: any) => {
      return currentNotes.map((note: any) => {
        if ((note.id || note.temporaryId) === id) {
          previousVersions = [{
            ...previousVersions,
            body: note.body,
            datelastmodified: formatDate(note.datecreated)
          }]
          return {
            ...note,
            body: encodeText(newNoteText),
            datelastmodified: Date.now(),
            attachments: [
              ...note?.attachments,
              {
                body: note.body,
                datelastmodified: note.datelastmodified ? note.datelastmodified : note.datecreated
              }]
          };
        }
        return note;
      });
    });
    setActiveSection('');

    try {
      notesService.updateNote(id, encodeText(updatedNotes), previousVersions)
      addAuditEvent('Edit Saved',"Notes / New Note", new Date(), registrationId)
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  }

  const filterNotesByCreator = notes.filter((note: any) => note.creator === currentUser);

  return (
    <>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        confirmDeleteNote={confirmDeleteNote}
        selectedNoteId={selectedNoteId}
      />
      {(view === "My Notes" ? filterNotesByCreator : notes).map((note: any) => (
          <NoteCard
            key={note.id}
            note={note}
            currentUser={currentUser}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            showEditsById={showEditsById}
            showEdits={showEdits}
            usersPermissions={usersPermissions}
            setOpen={setOpen}
            printNote={printNote}
            editNotes={editNotes}
            setUpdatedNotes={setUpdatedNotes}
            updateSelectedNote={updateSelectedNote}
            updateCurrentNotes={updateCurrentNotes}
            updatedNotes={updatedNotes}
            showPreviousNotes={showPreviousNotes}
            convertName={convertName}
          />
      ))}
    </>
  );
};
