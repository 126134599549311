import { Button, Grid } from "@mui/material";
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import { NoteCardButtonsProps } from '@propTypes/index';

export const NoteCardButtons: React.FC<NoteCardButtonsProps> = ({
    isEditMode,
    setOpen,
    printNote,
    note,
    noteCreator,
    usersPermissions,
    editNotes,
    setActiveSection,
    setUpdatedNotes,
    updatedNotes,
    updateSelectedNote,
    activeSection
}) => {

    const buttonStyle = {
        marginRight: '0.5rem',
    }

    let canPrintNotes = usersPermissions?.["printNotes"];
    let editOwnNotes = usersPermissions?.["editOwnNotes"];
    let editOthersNotes = usersPermissions?.["editOthersNotes"];
    let canDeleteNotes = usersPermissions?.["canDeleteNotes"];

    return (
        <Grid display='flex' alignItems='center' paddingRight='0.5rem'>
            {!isEditMode ? (
                <>
                    {canDeleteNotes &&
                        <Button
                            disableRipple
                            sx={buttonStyle}
                            size="small"
                            color='primary'
                            variant='text'
                            startIcon={<DeleteIcon color='primary' />}
                            onClick={() => setOpen(true)}
                        >
                            DELETE
                        </Button>}
                    {canPrintNotes &&
                        <Button
                            disabled={!canPrintNotes}
                            disableRipple
                            sx={buttonStyle}
                            size="small"
                            color='primary'
                            variant='text'
                            startIcon={<PrintIcon color='primary' />}
                            onClick={() => printNote(note)}
                        >
                            PRINT
                        </Button>}
                    {noteCreator &&
                        <Button
                            disabled={!editOwnNotes && !editOthersNotes}
                            disableRipple
                            sx={buttonStyle}
                            size="small"
                            color='primary'
                            variant='text'
                            startIcon={<EditIcon color='primary' />}
                            onClick={() => editNotes(note["temporaryId"] || note["id"])}
                        >
                            EDIT
                        </Button>}
                </>
            ) : (
                <>
                    <Button
                        disableRipple
                        sx={{ ...buttonStyle, color: 'text.primary' }}
                        size="small"
                        variant='outlined'
                        color='primary'
                        onClick={() => {
                            setActiveSection('');
                            setUpdatedNotes(note["body"]);
                        }}
                    >
                        CANCEL & RETURN
                    </Button>
                    <Button
                        disableRipple
                        sx={{ marginRight: '0.5rem', bgcolor: '#430099' }}
                        size="small"
                        variant='contained'
                        color='primary'
                        disabled={note["body"] === updatedNotes}
                        onClick={() => updateSelectedNote(updatedNotes, activeSection)}
                    >
                        SAVE CHANGES
                    </Button>
                </>
            )}
        </Grid>)
}

