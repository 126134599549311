import { Container, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { tableCellTypogrphyStyle } from "../styles"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

interface CustomTableCellProps {
    cell: { dataIndex: string, label: string, textWidth?: string }
    value: string,
    row,
    toggleVisibility: Function
    isCellRevealed: boolean
}

export const ShowHideTableCell: React.FC<CustomTableCellProps> = ({ isCellRevealed, toggleVisibility, row, cell, value }) => {
    if (!value) {
        return (
            <Typography sx={{ ...tableCellTypogrphyStyle, width: cell.textWidth ? cell.textWidth : "max-content", }}>-</Typography>
        )
    }
    return (
        //cell.textWidth is from batch-printing but we may no longer need this. See headerCells file in batch-printing page folder.
        //It's not actually being used anymore but that page needs to be checked in ME or Demo to make sure it's displaying properly. 
        //We may need to add textWidth to the config for that page.
        <>
            <Typography sx={{ ...tableCellTypogrphyStyle, width: cell.textWidth ? cell.textWidth : "max-content", }}>
                {
                    isCellRevealed
                        ? value
                        : '***-**-****'
                }
            </Typography>
            {
                isCellRevealed
                    ? <Tooltip title="Hide">
                        <IconButton size='small' onClick={(e) => {
                            e.stopPropagation()
                            //setIsCellRevealed(false)
                            toggleVisibility(row.key)

                        }}>
                            <VisibilityOffIcon sx={{ color: 'action.active' }} fontSize='small' />
                        </IconButton>
                    </Tooltip>

                    : <Tooltip title="Show">
                        <IconButton size='small' onClick={(e) => {
                            e.stopPropagation()
                            //setIsCellRevealed(true)
                            toggleVisibility(row.key)
                        }}>
                            <VisibilityIcon sx={{ color: 'action.active' }} fontSize='small' />
                        </IconButton>
                    </Tooltip>

            }
        </>
    )

}