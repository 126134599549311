import React from "react"
import { Button, CircularProgress, ButtonProps } from '@mui/material';

export const MFAPwButton: React.FC<any> = ({ 
    label, 
    loading, 
    onClick, 
    disabled, 
    variant, 
    btnRef, 
    color,
    style, 
    type
}) => {
    return (
        <Button
            fullWidth
            color={color}
            variant={variant}
            type={type}
            ref={btnRef}
            onClick={onClick}
            size="large"
            disabled={disabled}
            sx={style}       
        >
            {loading ? <CircularProgress size="1.5rem" /> : label}
        </Button>
    )
}