/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import ApiService from "utils/apiService";
import { formatPermissionsAfterFetch } from "components/UserPermissions/permissionsConfig";
import { urls } from "urls";
import { useTableStore, useUserStore } from "state";
import { handleUnauthorized } from "utils/handleUnauthorized";
import { useAuth } from "./useAuth";

const getUserRoleLabel = (roles: any, value: string): { label: string | undefined, value: string | undefined } => {
  const role = roles.find(role => role.value === value);
  return role !== undefined ? {
    label: role.label,
    value
  } : {
    label: value,
    value
  };
};

export const useUserCognito = () => {
  const [userRole, setUserRole] = useState<string | string[]>();
  const [userGroup, setUserGroup] = useState<string | string[]>();
  const [usersPermissions, setUsersPermissions] = useState<any>();
  const [userGivenName, setUserGivenName] = useState()
  const [userFamilyName, setUserFamilyName] = useState()
  const [groups, setGroups] = useState();
  const [userEmail, setUserEmail] = useState<string>()
  const groupUrl = urls.GROUP;
  const roleUrl = urls.ROLE;
  const groupRoleUrl = urls.GROUPROLE;
  const groupRoles: any = [];
  const userConfig = useUserStore(state => state.userConfig)
  const tableConfig = useTableStore(state => state.tableConfig)
  const config = useUserStore(state => state.config)
  const { logout } = useAuth();
  const { loggedIn } = userConfig


  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const groupsService = new ApiService(
      `${process.env.NODE_ENV === "production" ? groupUrl : ""}/group`
    );
    const rolesService = new ApiService(
      `${process.env.NODE_ENV === "production" ? roleUrl : ""}/role`
    );
    const groupRoleService = new ApiService(
      `${process.env.NODE_ENV === "production" ? groupRoleUrl : ""}/groupRole`
    );

    const getUserInfoFromCognito = async () => {
      console.log(`debug group call running getUserInfoFromCognito loggedIn ${loggedIn} useConfig: `, userConfig)
      const user = await Auth?.currentAuthenticatedUser();

      const idTokenPayload = user.signInUserSession.idToken.payload;
      if (idTokenPayload.given_name) {
        setUserGivenName(idTokenPayload.given_name)
      }
      if (idTokenPayload.family_name) {
        setUserFamilyName(idTokenPayload.family_name)
      }
      if (idTokenPayload.email) {
        setUserEmail(idTokenPayload.email)
      }

      const userGroups = idTokenPayload["cognito:groups"];
      const singleGroup = userGroups.filter((group) => group.startsWith('mc_'));

      try {
        const { data, status } = await groupsService.get();

        const allGroups = data
        setGroups(allGroups);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        const resp = await groupRoleService.get(singleGroup.join())
        handleUnauthorized(resp.message, logout);

        groupRoles.push(resp.data)
        const formattedPermissions = await formatPermissionsAfterFetch(
          groupRoles
        );

        setUsersPermissions(formattedPermissions);

        if (userGroups.length) {
          const singleGroup = userGroups.filter((group) => group.startsWith('mc_'));
          const userRole = singleGroup[0];
          console.log('debug getUserRoleLabel config', getUserRoleLabel(config.cognito.userRoles, userRole))
          const labelUserRole = getUserRoleLabel(config.cognito.userRoles, userRole);
          if (labelUserRole) {
            setUserRole(labelUserRole.label);
            setUserGroup(labelUserRole.value);
          }
          // if (userRole) setUserRole(userRole);
        } else {
          console.log(`httpOnly ${user.userusername} doesn't belong to any groups`);
        }
      } catch (err: any) {
        console.log('err: ', err);
        return { errorMessage: err.message };
      }
    }

    console.log(`debug group call running getUserInfoFromCognito loggedIn ${loggedIn} useConfig: `, userConfig)

    //AppBar callues useAuth for logout() which recalls useUserCognito and hits end points again that we already have saved 
    if (loggedIn /*&& !userConfig.userRole*/) {
      getUserInfoFromCognito();
    } else {
      console.log(`debug userConfig loggedIn ${loggedIn}`)
    }

    return () => controller.abort();
  }, [loggedIn]);

  console.log(`debug userConfig useUserCognito types loggedIn ${loggedIn}: `, {
    userRole: typeof userRole, usersPermissions: typeof usersPermissions, groups: typeof groups, userGivenName: typeof userGivenName, userFamilyName: typeof userFamilyName, userEmail: typeof userEmail
  })

  return { userRole, userGroup, usersPermissions, groups, userGivenName, userFamilyName, userEmail };
};
