import { create } from "zustand"

interface UserManagementState {
    paginationToken: string
    setPaginationToken: (paginationToken: string) => void
    confirmModalTitle: string
    setConfirmModalTitle: (confirmModalTitle: string) =>  void
    confirmModalContent: any
    setConfirmModalContent: (confirmModalContent: any) => void
    cancelButtonText: string
    setCancelButtonText: (cancelButtonText: string) => void
    confirmButtonText: string
    setConfirmButtonText: (confirmButtonText: string) => void
    handleConfirmAction: Function
    setHandleConfirmAction: (handleConfirmAction: Function) => void
    handleCancelAction: Function
    setHandleCancelAction: (handleCancelAction: Function) => void
    openConfirmModal: boolean
    setOpenConfirmModal: (openConfirmModal: boolean) => void
    isActive: boolean
    setIsActive: (isActive: boolean) => void
    addOriRfpGroups: Function
    setAddOriRfpGroups: (addOriRfpGroups: Function) => void
    removeOriRfpGroups: Function
    setRemoveOriRfpGroups: (addOriRfpGroups: Function) => void
    selectedRow: object
    setSelectedRow: (selectedRow: any) => void
    selectedUserStatus: string
    setSelectedUserStatus: (selectedUserStatus: string) => void
    disableAddUser: boolean
    setDisableAddUser: (disableAddUser: boolean) => void
    addNewUserLoading: boolean
    setAddNewUserLoading: (addNewUserLoading: boolean) => void
    removedOriRfp: any,
    setRemovedOriRfp: (removedOriRfp: any) => void

}

export const useUserManagementStore = create<UserManagementState>((set, get) => ({
    paginationToken: "",
    setPaginationToken: (paginationToken: string) => set({paginationToken}),
    confirmModalTitle: "",
    setConfirmModalTitle: (confirmModalTitle: string) => set({confirmModalTitle}),
    confirmModalContent: "",
    setConfirmModalContent: (confirmModalContent: any) => set({confirmModalContent}),
    cancelButtonText: "",
    setCancelButtonText: (cancelButtonText: string) => set({cancelButtonText}),
    confirmButtonText: "",
    setConfirmButtonText: (confirmButtonText: string) => set({confirmButtonText}),
    handleConfirmAction: () => {},
    setHandleConfirmAction: (handleConfirmAction: Function) => set({handleConfirmAction}),
    handleCancelAction: () => {},
    setHandleCancelAction: (handleCancelAction: Function) => set({handleCancelAction}),
    openConfirmModal: false,
    setOpenConfirmModal: (openConfirmModal: boolean) => set({openConfirmModal}),
    isActive: true,
    setIsActive: (isActive: boolean) => set({isActive}),
    addOriRfpGroups: () => {},
    setAddOriRfpGroups: (addOriRfpGroups: Function) => set({addOriRfpGroups}),
    removeOriRfpGroups: () => {},
    setRemoveOriRfpGroups: (removeOriRfpGroups: Function) => set({removeOriRfpGroups}),
    selectedRow: {},
    setSelectedRow: (selectedRow: any) => set({selectedRow}),
    selectedUserStatus: "",
    setSelectedUserStatus: (selectedUserStatus: string) => set({selectedUserStatus}),
    disableAddUser: true,
    setDisableAddUser: (disableAddUser: boolean) => set({disableAddUser}),
    addNewUserLoading: false,
    setAddNewUserLoading: (addNewUserLoading: boolean) => set({addNewUserLoading}),
    removedOriRfp: [],
    setRemovedOriRfp: (removedOriRfp: any) => set({removedOriRfp})

}))