import { create } from "zustand"
import { persist, createJSONStorage } from 'zustand/middleware'

export interface ApplicantDetailsState {
    tcn: string
    ori: string
    incompleteStatuses: string[]
    setTcn: Function
    setOri: Function
    setIncompleteStatuses: Function
}

const initialState: ApplicantDetailsState = {
    tcn: "",
    ori: "",
    incompleteStatuses: [],
    setTcn: () => { },
    setOri: () => { },
    setIncompleteStatuses: () => {}
}

export const useApplicantDetailsStore = create<ApplicantDetailsState>((set, get) => ({
    ...initialState,
    setTcn: (tcn: string) => set({ tcn }),
    setOri: (ori: string) => set({ ori }),
    setIncompleteStatuses: (incompleteStatuses: string[]) => set({incompleteStatuses})
}))