import { create } from "zustand"

interface LoginState {
    labelDescriptionText: string,
    labelText: string,
    errorMessage: string,
    copySuccess: string,
    errorDescription: string,
    alertTitle: string,
    alertDescription: string,
    severity: any,
    openAlert: boolean,
    stateName: string
   
    setOpenAlert: (openAlert: boolean) => void
    setAlertTitle: (alertTitle: string) => void
    setAlertDescription: (alertDescription: string) => void
    setLabelDescriptionText: (labelDescriptionText: string) => void
    setLabelText: (labelText: string) => void
    setErrorMessage: (errorMessage: string) => void
    setCopySuccess: (copySuccess: string) => void
    setErrorDescription: (errorDescription: string) => void
    setSeverity: (severity: string) => void
    setStateName: (severity: string) => void
}

export const useLoginStore = create<LoginState>((set, get) => ({
    labelDescriptionText: "",
    labelText: "",
    errorMessage: "",
    copySuccess: "",
    errorDescription: "",
    alertTitle: "",
    alertDescription: "",
    severity: "",
    openAlert: false,
    stateName: "",

    setStateName: (stateName: string) => set({stateName}),
    setOpenAlert: (openAlert: boolean) => set({openAlert}),
    setLabelDescriptionText: (labelDescriptionText: string) => set({ labelDescriptionText }),
    setLabelText: (labelText: string) => set({ labelText }),
    setErrorMessage: (errorMessage: string) => set({ errorMessage }),
    setErrorDescription: (errorDescription: string) => set({ errorDescription }),
    setCopySuccess: (copySuccess: string) => set({ copySuccess }),
    setAlertTitle: (alertTitle: string) => set({ alertTitle }),
    setAlertDescription: (alertDescription: string) => set({ alertDescription }),
    setSeverity: (severity: any) => set({ severity }),
}));