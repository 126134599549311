/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { Navigate, useNavigate } from 'react-router-dom'
import { Appbar } from 'components/AppBar/AppBar'
import { Outlet, useOutletContext, useLocation } from "react-router-dom";
import {
    Box, Button,
    Container,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Typography
} from '@mui/material';
import NavLinks from "components/AppBar/components/NavLinks"
import React, { useState } from 'react';
import { useMUITheme } from 'theme/ThemeProvider';
import { DrawerHeader } from "../../common/DrawerHeader"
import { AppLayoutStyle } from 'customStyles/index';
import { ErrorBoundary } from "react-error-boundary"
import { useUserStore, useCustomModalStore } from 'state';
import darkModeLogo from '../../assets/logo/loginLoadingGif.gif'
import lightModeLogo from '../../assets/logo/Light Mode Loading.gif'
import { CustomModal } from 'components/AppBar/components/CustomModal';
type ContextType = { expanded: boolean }

const ErrorModal: React.FC<any> = ({ error, resetErrorBoundary }) => {
    console.log('ErrorModal error: ', Object.keys(error))
    return (
        <Dialog PaperProps={{
            sx: {
                minWidth: "600px", minHeight: "221px", backgroundColor: 'background.default'
            }
        }} open={true}>
            <Paper sx={{ minWidth: "600px", minHeight: "221px", bgcolor: 'background.default' }}>
                <DialogTitle>Unexpected Error</DialogTitle>
                <DialogContent sx={{ height: "100%", paddingRight: '2.5rem' }}>
                    <Typography>
                        We have experienced an unexpected error and have returned you to Transaction Summary section. We apologize, if this continues
                        to happen please contact support.
                    </Typography>


                </DialogContent>
                <DialogActions sx={{ p: '1rem 1.5rem', gap: '0.5rem' }}>
                    {/* <Button onClick={() => handleCancel()} variant="outlined" sx={{ color: mode === 'dark' ? 'text.primary' : '#000' }}>
                        {cancelButtonText}
                    </Button>*/}
                    <Button onClick={() => resetErrorBoundary()} variant="contained" color='secondary'>
                        ACKNOWLEDGE
                    </Button>


                </DialogActions>
            </Paper>
        </Dialog>
    )
}

export const ProtectedLayout = () => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const location = useLocation();
    const applicantDetails = location.pathname.includes('applicant-details')
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const userConfig = useUserStore(state => state.userConfig)
    const userConfigLoaded = useUserStore(state => state.userConfigLoaded)
    const { mode } = useMUITheme();
    const isDark = mode === 'dark';
    const dialogStyles = useCustomModalStore(state => state.dialogStyles)
    const openCustomModal = useCustomModalStore(state => state.openCustomModal)
    const customModalTitle = useCustomModalStore(state => state.customModalTitle)
    const contentText = useCustomModalStore(state => state.contentText)
    const contentStyle = useCustomModalStore(state => state.contentStyle)
    const progressVariant = useCustomModalStore(state => state.progressVariant)
    const modalActionsConfig = useCustomModalStore(state => state.modalActionsConfig)
    
    console.log('ProtectedLayout userConfig: ', userConfig)

    if (!userConfig.loggedIn) {
        return <Navigate to="/" />
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    console.log('debug loading userConfig userConfigLoaded: ', userConfigLoaded)

    return (
        <ErrorBoundary FallbackComponent={ErrorModal} onReset={() => navigate("/app/transaction-summary", { replace: true })}>
            {userConfigLoaded ? <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Appbar open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/> <Container maxWidth={false} disableGutters sx={AppLayoutStyle.appContainerStyle}>
                    {!applicantDetails && (
                        <Paper sx={{ ...AppLayoutStyle.navLinksContainerStyle, minWidth: expanded ? "256px" : "64px" }} elevation={0}>
                            <NavLinks
                                handleDrawerOpen={handleDrawerOpen}
                                handleDrawerClose={handleDrawerClose}
                                expanded={open}
                                setOpen={setOpen}
                            />
                        </Paper>)
                    }
                    <Box component="main" sx={{ flexGrow: 1, backgroundColor: 'background.paper' }}>
                        <Container maxWidth={false} disableGutters sx={{
                            ...AppLayoutStyle.protectedLayoutContainer,
                            width: applicantDetails ? "100%" : open
                                ? "calc(100vw - 256px)" : "calc(100vw - 64px)",
                        }} >
                            <DrawerHeader />
                           
                            <Outlet context={open} />
                        </Container>
                    </Box>
                    <CustomModal
                                modalActionsConfig={modalActionsConfig}
                                progressVariant={progressVariant}
                                dialogStyles={dialogStyles}
                                open={openCustomModal}
                                modalTitle={customModalTitle}
                                contentText={contentText}
                                contentStyle={contentStyle}
                            />
                </Container>
            </Box>
                :
                <Paper sx={{ ...AppLayoutStyle.loginBackground, background: isDark ? 'linear-gradient(180deg, #10111C 0%, #1C1D27 100%)' : '#F4F5F9' }} elevation={0}>
                    <Grid height="100vh" justifyContent='center' alignItems='center' display='flex' flexDirection='column'>
                        <img src={isDark ? darkModeLogo : lightModeLogo} style={{ width: '3.75rem', height: '3.75rem' }} />
                        <Typography sx={{ color: isDark ? '#BC8FFF' : '#430099' }} variant='h6'>Loading...</Typography>
                    </Grid>
                </Paper>
            }
        </ErrorBoundary>
    )
}

export function useExpanded() {
    return useOutletContext<ContextType>()
}
