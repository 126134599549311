/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useUserRole } from './useUserRole';
import { urls } from '../urls'
import { useState, useEffect } from 'react';
import { generateAgencyQueryParams } from 'utils';
import { QueryRequestBody } from 'interfaces/common/QueryRequestBody';
import { useUserStore } from 'state';
import { handleUnauthorized } from 'utils/handleUnauthorized';
import { useAuth } from './useAuth';

const transform = (data: any, i: number) => {
    console.log('agencyManagement transform data: ', data)
    return {
        "id": data.ori,
        "isActive": true,
        "json_data":
        {
            id: data.id,
            ori: data.ori,
            secondaryOri: data.secondaryOri ? data.secondaryOri : "N/A",
            reasonFingerPrinted: data.reasonFingerPrinted,
            agency_name: data.agency_name,
            distribution_method: data.distribution_method,
            agency_email: data.agency_email,
            agency_address: data.agency_address,
            agency_city: data.agency_city,
            agency_county: data.agency_county,
            agency_state: data.agency_state,
            agency_zipcode: data.agency_zipcode,
            isActive: data.isActive
        }
    }
}

export const useQueryAgencyManagement = (
    pageSize: number,
    currentPage: number,
    order: string,
    orderBy: string,
    tableFiltersData: any,
    searchParams: any) => {

    const [mappedData, setMappedData] = useState<any>()
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false)
    const [countError, setError] = useState();
    const userConfig = useUserStore(state => state.userConfig)
    const { userRole } = useUserRole()
    const { logout } = useAuth();

    const url = urls.QUERY

    useEffect(() => {
        const controller = new AbortController()
        const queryParams = generateAgencyQueryParams(orderBy, order, searchParams)
        let queryDataRequest: QueryRequestBody = {
            "queries": [
                {
                    "queryParams": [...queryParams],
                    "limit": pageSize,
                    "offset": (currentPage - 1) * pageSize,
                    "queryName": "queryAgencyTable"
                }
            ]
        }

        let queryCountRequest: QueryRequestBody = {
            "queries": [
                {
                    "queryParams": [...queryParams],
                    "limit": pageSize,
                    "offset": (currentPage - 1) * pageSize,
                    "queryName": "queryAgencyTableCount"
                }
            ]
        }

        const fetchAgencyManagementData = async (queryDataRequest: QueryRequestBody, queryCountRequest: QueryRequestBody) => {
            console.log('useQueryAgencyManagement fetchAgencyManageMentData running')
            setLoading(true);
            try {
                const agencyManagementUrl: string = `${process.env.NODE_ENV === "production" ? url : ""}/query`;

                const data = await (await fetch(agencyManagementUrl, {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify(queryDataRequest)
                })).json();

                handleUnauthorized(data.message, logout);

                console.log('debug useQueryAgencyManagement data: ', data)

                const countData = await (await fetch(agencyManagementUrl, {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify(queryCountRequest)
                })).json();

                console.log('useQueryAgencyManagement countData: ', countData)

                const { results } = data['queriesResults'][0];
                console.log('useQueryAgencyManagement Agency results', results);

                const count = countData['queriesResults'][0].results[0]['count'];
                console.log("useQueryAgencyManagement Agency Count: " + count);

                const transformedData = results.map((obj: any, index: number) => transform(obj, index));
                console.log('useQueryAgencyManagement transformedData agency: ', transformedData)
                setMappedData(transformedData);
                setTotalRecords(count);
                setLoading(false);
            } catch (err: any) {
                setError(err);
                setLoading(false);
            }
        }
        fetchAgencyManagementData(queryDataRequest, queryCountRequest)
        return () => {
            controller.abort();
        }
    }, [url, order, orderBy, currentPage, pageSize, userRole, tableFiltersData, searchParams])

    return { mappedData, totalRecords, countError, loading }
}