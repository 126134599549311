import { TableCell, Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { HeaderCell, useTableStore } from 'state';
import { tableBodyCellStyle, tableCellTypogrphyStyle } from "../styles"

interface CustomTableCellProps {
    cell: { dataIndex: string, label: string }
    value: string,
    children: any
}

export const SimpleTableCell: React.FC<CustomTableCellProps> = ({ cell, value, children }) => {

    return (

        <Typography sx={tableCellTypogrphyStyle}>
            {value && value}
            {children}
        </Typography>

    )
}

