import React from "react"
import { TextField, InputAdornment, IconButton, Tooltip, TextFieldVariants } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface MFAPwFieldProps {
    id: string, 
    label: string, 
    value?: string, 
    onChange: (e) => void, 
    showPassword: boolean, 
    togglePW: (e) => void,
    inputRef?, 
    error?: boolean, 
    helperText?: string,
    type: string,
    autoComplete: string,
    toggleMouseDown: (e) => void,
    name: string,
    variant: TextFieldVariants | undefined
  }

export const MFAPwField: React.FC<MFAPwFieldProps> = ({ 
    id, 
    label, 
    value, 
    onChange, 
    showPassword, 
    togglePW, 
    inputRef, 
    error, 
    helperText,
    type,
    autoComplete,
    toggleMouseDown,
    variant, 
    name
}) => {
    return (
        <TextField
            id={id}
            label={label}
            value={value}
            onChange={onChange}
            name={name}
            required
            fullWidth
            autoFocus
            type={type}
            variant={variant}
            inputRef={inputRef}
            error={error}
            helperText={helperText}
            autoComplete={autoComplete}
            InputLabelProps={{ style: { color: 'text.secondary' } }}
            InputProps={{
                style: { color: 'text.primary' },
                endAdornment: (
                    <InputAdornment position="end">
                        <Tooltip title="Toggle Password Visibility">
                        <IconButton
                                    aria-label="toggle-password-visibility"
                                    onClick={togglePW}
                                    onMouseDown={toggleMouseDown}
                                    style={{ color: '#808086' }}
                                    >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ) 
            }}
        />
    )
}