import { Config, Table, Tables, TableConfig } from "interfaces/config";
import { TableNames } from "customEnums/TableNames";
import { useTableStore } from "state";

export const generateTableConfig = (config: Config, pageName: string, tableName: string, usersPermissions: object): TableConfig => {
    try {
        let tables: Tables = config.pages[pageName].tables
        let tableHeaderCells = tables.find((table: Table) => table.tableName === tableName)!.tableHeaderCells
        let filter = tables.find((table: Table) => table.tableName === tableName)!.tableTools.filter
        let manageColumns = tables.find((table: Table) => table.tableName === tableName)!.tableTools.manageColumns
        let columnVisibility = tables.find((table: Table) => table.tableName === tableName)!.columnVisibility
        let advancedSearch = tables.find((table: Table) => table.tableName === tableName)!.advancedSearch
        let filterList = tables.find((table: Table) => table.tableName === tableName)!.filterList
        let useQueryPagination = tables.find((table: Table) => table.tableName === tableName)!.useQueryPagination
        let tableHeaderSorting = tables.find((table: Table) => table.tableName === tableName)!.tableHeaderSorting

        const tableConfig: TableConfig = {
            [tableName]: {
                advancedSearch,
                columnVisibility,
                filterList,
                tableHeaderCells,
                filter,
                manageColumns,
                useQueryPagination,
                config,
                transactionViewing: config.transactionViewing,
                tableHeaderSorting
            },

            //userRoles: config.cognito.userRoles, move userRoles to userConfig probable, EditUserDrawer refernces usersRoles = tableConfig login 
        }
        return tableConfig
    } catch (error) {
        throw new Error(`Error generating Table Config: ${error}`)
    }
}

