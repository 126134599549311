import { Drawer, Box, Grid, Button } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { useTransactionSummaryStore } from "state/TransactionSummaryStore";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { extractPageName } from "./CustomTable/helpers";
import { PageNames } from "interfaces/config";

interface RightDrawerProps {
    children: any
    openDrawer: boolean
    closeDrawer: any
    containsTable: boolean,
    name?: string
}
export const RightDrawer: React.FC<RightDrawerProps> = ({
    children,
    openDrawer,
    closeDrawer,
    containsTable,
    name
}) => {

    const locationObj = useLocation()
    const pageName = extractPageName(locationObj.pathname)
    const loadingRejectCodes = useTransactionSummaryStore(state => state.loadingRejectCodes)
    return (
        <Drawer anchor='right' open={openDrawer} variant={"temporary"} onClose={closeDrawer} sx={{ overflowY: "hidden", zIndex: 1202}}>
            <Box sx={{display: "flex", flexDirection: "column", height: "100%", width: "600px", padding: 3, backgroundColor: 'background.default'}}>
                <Grid container justifyContent='flex-end' alignItems='center' sx={{ height: "30px", pb: '2rem', position: 'sticky', top: 0 }}>
                    <Button 
                        disabled={pageName === PageNames.TRANSACTION_SUMMARY ? loadingRejectCodes : false} 
                        size='small' 
                        startIcon={<CloseIcon />} sx={{ color: 'text.primary' }} 
                        onClick={closeDrawer}>CLOSE</Button>
                </Grid>
                <Grid sx={{flexWrap: "nowrap", display: "flex", ...(containsTable && {height: "calc(100% - 30px)"})}} container display='block' flexDirection='column' justifyContent='center' flexGrow={1}>
                   {
                     children
                   }
                </Grid>
            </Box>
        </Drawer>
    )
}