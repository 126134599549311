import * as React from 'react';
import { pageStyles } from "customStyles/common";
import {
    Button,
    Container,
    Paper,
    Typography,
} from "@mui/material";
import {
    ConfirmModal,
    CustomTable,
    CustomTableTitleAndAlert,
    RightDrawer
} from "../../../common";
import { useCallback, useEffect, useReducer, useState } from "react";
import { Order } from "@customTypes/OrderType";
import { ColumnState, useTableStore, useUserStore } from "../../../state";
import { useExpanded } from "../../../components/layouts/ProtectedLayout";
import { useRapbackSubscription } from "../../../hooks/useRapbackSubscription";
import { useLocation } from "react-router-dom";
import { extractPageName } from "../../../common/CustomTable/helpers";
import { editSubscriptionReducer } from "../../TransactionSummary/helpers/searchReducer";
import { useRapbackMaintenanceStore } from "./state";
import dayjs, { Dayjs } from 'dayjs';
import { useAudit } from "hooks/useAudit";
import { AuditHistoryTable } from '../../../common/AuditHistoryTable';
import { generateTableConfig } from 'utils';
import { EditSubscriptionForm } from "./EditSubscriptionForm";
import { TableNames } from 'customEnums/TableNames';
import { useConfigService, useSetConfig } from 'hooks';
import { savedSearchesReducer } from 'pages/TransactionSummary/helpers/savedSearchesReducer';

export enum EditSubscriptionAction {
    DOB = 'DOB',
    EXPIRATION_DATE = 'EXPIRATION_DATE',
    SET_VALUES = 'SET_VALUES',
    FIRST_NAME = 'FIRST_NAME',
    STATUS = 'STATUS',
    TYPE = 'TYPE',
    LOWER_DATE = 'LOWER_DATE',
    UPPER_DATE = 'UPPER_DATE',
    RFP = 'RFP',
    DAYS = 'DAYS',
    RESET = 'RESET',
    TCN = 'TCN'
}

export const RapbackMaintenance: React.FC = () => {
    const locationObj = useLocation();
    const currentPageName = extractPageName(locationObj.pathname)
    const { addAuditEvent } = useAudit();

    //Local States
    const [data, setData] = useState<[]>([])
    const [sortOrder, setSortOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<string>('subscriptionDate');
    const [searched, setSearched] = useState<boolean>(false); //Set true when an advanced form multi parameter search has run
    const [value, setValue] = React.useState<any>([]);
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openAuditDrawer, setOpenAuditDrawer] = useState(false)
    const [id, setId] = useState("");
    const [displaySSN, setDisplaySSN] = useState('')
    const [showSSN, setShowSSN] = useState<boolean>(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [confirmModalTitle, setConfirmModalTitle] = useState("")
    const [confirmModalContent, setConfirmModalContent] = useState("")
    const [cancelButtonText, setCancelButtonText] = useState("")
    const [confirmButtonText, setConfirmButtonText] = useState("")
    const [action, setAction] = useState("")
    const [applicantName, setApplicantName] = useState()
    const [internalrapbackId, setrapbackId] = useState("")
    const [staticExpiration, setStaticExpiration] = useState<any>('N/A');
    const [staticDoB, setStaticDoB] = useState<any>('N/A');
    const [disabled, setDisabled] = useState<boolean>(false);

    //Table Store
    const setPagination = useTableStore(state => state.setPagination)
    const pagination = useTableStore((state) => state.pagination)
    const setOpenNotification = useTableStore((state) => state.setOpenNotification)
    const setOpenAuditHistory = useTableStore(state => state.setOpenAuditHistory)
    const pageName = extractPageName(locationObj.pathname);

    const openNotification = useTableStore((state) => state.openNotification)
    const searchParams = useTableStore((state) => state.searchParams)
    const setSearchParams = useTableStore((state) => state.setSearchParams)
    const setActionsConfig = useTableStore(state => state.setActionsConfig)
    const simpleSearchRan = useTableStore((state) => state.simpleSearchRan)


    //Maintenance Table Store
    const setFullName = useRapbackMaintenanceStore(state => state.setFullName)
    const setOpenDrawerHandler = useRapbackMaintenanceStore((state) => state.setOpenDrawerHandler)
    setOpenDrawerHandler(setOpenDrawer)


    //const [page, setPage] = useState(1)
    //const [rowsPerPage, setRowsPerPage] = useState(25)

    //Hooks
    const { expanded } = useExpanded()
    const userConfig = useUserStore(state => state.userConfig)

    const { userRole, usersPermissions } = userConfig;
    const [formState, dispatchFormValues] = useReducer(editSubscriptionReducer, { expirationDate: '', dob: '' })

    const isPost = useTableStore((state) => state.isPost)
    const isDelete = useTableStore((state) => state.isDelete)
    const configFile = useTableStore((state) => state.configFile)
    const configBody = useTableStore((state) => state.configBody)
    const { searchData, alertData, postedConfig } = useConfigService(configFile, configBody, true, isPost, isDelete);

    const loadedData = React.useMemo(() => ({ ...searchData }), [searchData]);
    const [savedSearches, dispatchSearch] = useReducer(savedSearchesReducer, loadedData);

    const setTableConfig = useTableStore(state => state.setTableConfig)
    const tableConfig = useTableStore(state => state.tableConfig)
    //const { tableHeaderCells } = tableConfig
    const config = useUserStore(state => state.config)

    const setDisplayNoSearchResults = useTableStore(state => state.setDisplayNoSearchResults)
    const setDefaultOrderBy = useTableStore(state => state.setDefaultOrderBy)



    useEffect(() => {
        setDefaultOrderBy("subscriptionDate")
        setPagination({ ...pagination, [TableNames.RAPBACK_MAINTENANCE]: { page: 1, rowsPerPage: 25 } })
    }, [])

    useEffect(() => {
        const { sortOrder } = pagination[TableNames.RAPBACK_MAINTENANCE]
        console.log('debug reset sort order: ', pagination)
        if(sortOrder) setSortOrder(sortOrder)

      }, [pagination[TableNames.RAPBACK_MAINTENANCE]])

    const handleOpenAuditHistory = async (e, row) => {
        console.log('mappedData - Audit History row: ', row)
        setOpenAuditDrawer(true);
        setId(row.rapbackSubId)
        setApplicantName(row.fullName)
    }

    const handleConfirmUnsubscribe = (e, row) => {
        setrapbackId(row.rapbackSubId)
        setAction("unsubscribe")
        setConfirmModalTitle("CONFIRM UNSUBSCRIBE REQUEST")
        setConfirmModalContent(`Are you sure you want to unsubscribe ${row.fullName} from receiving Rapbacks ? This action cannot be undone.`)
        setCancelButtonText("CANCEL")
        setConfirmButtonText("UNSUBSCRIBE")
        setOpenConfirmModal(true)
        setFullName(row.fullName)
    }

    //Handlers
    const handleOpenEditSubscriptions = useCallback((e, row: any) => {
        const expirationConfig = config.pages[pageName].expirationOptions;
        if (expirationConfig) {
            switch (expirationConfig.action) {
                case 'shorten':
                    setStaticExpiration(dayjs(row.expirationDate).subtract(expirationConfig.days, 'days'));
                    break;
                case 'extend':
                    setStaticExpiration(dayjs(row.expirationDate).add(expirationConfig.days, 'days'));
                    break;
            }
        }
        setStaticDoB(dayjs(row.dob))
        dispatchFormValues(
            {
                type: EditSubscriptionAction.SET_VALUES,
                value: {
                    fullName: row.fullName,
                    dob: row.dob,
                    rapbackSubId: row.rapbackSubId,
                    ori: row.ori,
                    subscriptionDate: row.subscriptionDate,
                    expirationDate: row.expirationDate,
                    confirmation: row.subscriptionDate,
                    status: row.status
                }
            }
        );
        setOpenDrawer(true)
        setDisabled(true)
    }, [config])

    const actionsConfig: { actionLabel: string, actionHandler?: Function }[] = [
        {
            actionLabel: "Unsubscribe",
            actionHandler: handleConfirmUnsubscribe
        },
        {
            actionLabel: "_divider",
            actionHandler: undefined
        },
        {
            actionLabel: "Edit Subscription",
            actionHandler: handleOpenEditSubscriptions
        },
        {
            actionLabel: "Audit History",
            actionHandler: handleOpenAuditHistory
        }
    ]
    const { tableHeaderCells, page, rowsPerPage } = useSetConfig(pageName, { page: 1, rowsPerPage: 25 }, TableNames.RAPBACK_MAINTENANCE, {}, actionsConfig)

    //Data
    const { data: mappedData, runCount, totalRecords, loading, handleUnsubscribe, handleUpdateSubscription } = useRapbackSubscription(pageName, rowsPerPage,
        page,
        sortOrder,
        orderBy,
        userRole,
        searchParams, true, TableNames.RAPBACK_MAINTENANCE)

    console.log(`Maintenance data: `, mappedData)

    useEffect(() => {
        setData(
            mappedData?.map((data: any) => {
                return {
                    key: data.key,
                    fullName: data.fullName,
                    dob: data.dob,
                    ssn: data.ssn,
                    lsTcn: data.lsTcn,
                    rapbackSubId: data.rapbackSubId, //is this rapback id?
                    ori: data.ori,
                    //secondaryOri: data.secondaryOri,
                    subscriptionDate: data.subscriptionDate,
                    expirationDate: data.expirationDate,
                    deletionDate: data.deletionDate,
                    status: data.status
                }
            })
        );
    }, [mappedData?.length, mappedData])

    useEffect(() => {

        console.log('debug stuck search results useEffect: ', { runCount, searchParams, mappedData, simpleSearchRan, searched, setDisplayNoSearchResults: mappedData?.length === 0 && simpleSearchRan || mappedData?.length === 0 && searched })
        console.log('debug stuck search results useEffect: ', { conditional: runCount < 1 && searchParams.length > 0, negativeConditional: !(runCount < 1 && searchParams.length > 0) })
        if (runCount < 1 && searchParams.length > 0) {
            setDisplayNoSearchResults(false)
            setOpenNotification(false)
            return
        }
        if (mappedData?.length === 0 && simpleSearchRan || mappedData?.length === 0 && searched) {
            setDisplayNoSearchResults(true)
            setOpenNotification(true)
        }
        //console.log('debug open notification display no seasrch', displayNoSearchResults)
        console.log('debug open notification', openNotification)

    }, [mappedData?.length, simpleSearchRan, searched])

    useEffect(() => {
        console.log('createSearch formState: ', formState)
        const formStateProps = Object.keys(formState)
        if (formStateProps.length === 1 && formStateProps[0] === "days") {
            console.log('createSearch update searchParams')
            setSearchParams([{ column: "days", value: formState.days }])
        }
    }, [Object.keys(formState).length])

    const handleClickShowSSN = useCallback(async (record: string, e: any) => {
        if (displaySSN !== record) {
            setDisplaySSN(record)
            setShowSSN(!showSSN)

        } else {
            setDisplaySSN('')
            setShowSSN(!showSSN)
        }
    }, [displaySSN])

    //Potentially make generic and import
    const handleRequestSort = useCallback((event: any, property) => {
       
        console.log(`sort date handleRequestSort sortOrder: `, {sortOrder, orderBy, property})
        const isAsc = orderBy === property && sortOrder === 'asc';
        console.log(`sort date handleRequestSort sortOrder: isAsc ${isAsc}`)
        if(property === "firstName" && orderBy === "lastName" || property === "lastName" && orderBy === "firstName") {
            setSortOrder(sortOrder === "desc" ? 'asc' : "desc")
        } else {
            setSortOrder(isAsc ? 'desc' : 'asc')
        }
        setOrderBy(property);
    }, [orderBy, sortOrder]);

    const EditSubTextDisplay: React.FC<{ title: string, value: string }> = ({ title, value }) => {
        return (
            <Container disableGutters sx={{ display: "flex", flexDirection: "column" }}>
                <Typography>{title.toUpperCase()}</Typography>
                <Typography>{value}</Typography>
            </Container>
        )
    }

    const EditSubTextRow: React.FC<any> = ({ children }) => {
        return (
            <Container disableGutters sx={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                {children}
            </Container>
        )
    }

    const handleCancel = () => {
        setOpenDrawer(false);
        setOpenAuditDrawer(false);
        setDisabled(false);
        setPagination({
            ...pagination,
            [TableNames.RB_SUB_AUDIT_HISTORY]: {
                page: 0,
                rowsPerPage: 5,
                sortOrder: "desc",
                orderBy: ""
            }
        });

    }

    useEffect(() => {
        if (openNotification) {
            setTimeout(() => {
                setOpenNotification(false)
            }, 5000)
        }
    })

    const { dob, expirationDate, fullName, rapbackSubId, ori, confirmation, status, subscriptionDate } = formState

    console.log('maintenance formState: ', formState)

    const updateSubscription = useCallback(async (row) => {
        console.log(`1010101 debug expiration date updateSubscription dob ${dayjs(dob).format("MM/DD/YYYY")}  expirationDate ${dayjs(expirationDate).format("MM/DD/YYYY")} `)
        setOpenDrawer(false)
        addAuditEvent("Subscription Edit Request Submitted", "Rapback Maintenance", new Date(), row.rapbackSubId)
        const dobToSend: string | undefined = (dayjs(staticDoB).format("MM/DD/YYYY") !== dayjs(dob).format("MM/DD/YYYY"))
            ? dayjs(dob).format("MM/DD/YYYY") : undefined
        const expToSend: string | undefined = (dayjs(staticExpiration).format("MM/DD/YYYY") !== dayjs(expirationDate).format("MM/DD/YYYY"))
            ? dayjs(expirationDate).format("MM/DD/YYYY") : undefined
        handleUpdateSubscription(rapbackSubId, expToSend, dobToSend)
    }, [formState])

    const [internalExpirationDate, setInternalExpirationDate] = useState<Dayjs | null>(dayjs(expirationDate))

    useEffect(() => {
        setInternalExpirationDate(dayjs(expirationDate))
    }, [expirationDate]);

    return (
        <>
            <Paper elevation={0} sx={
                {
                    ...pageStyles.pagePaperStyle,
                    maxWidth: `calc(100vw - ${expanded ? "256px" : "64px"})`
                }}>
                <CustomTableTitleAndAlert name="Maintenance" />

                <RightDrawer containsTable={true} openDrawer={openAuditDrawer} closeDrawer={handleCancel}>
                    <Container sx={{ marginBottom: "18px" }} disableGutters>
                        <Typography sx={{ fontSize: '1.5rem' }}>{applicantName} Audit History</Typography>
                    </Container>
                    <AuditHistoryTable id={id} name={TableNames.RB_SUB_AUDIT_HISTORY} />
                </RightDrawer>

                <RightDrawer containsTable={false} openDrawer={openDrawer} closeDrawer={handleCancel}>
                    <Container sx={{ marginBottom: "24px", mt: '24px' }} disableGutters>
                        <Typography variant="h5">Edit Subscription</Typography>
                    </Container>
                    <EditSubscriptionForm staticDoB={staticDoB} staticExpiration={staticExpiration} formState={[formState, dispatchFormValues]} setDisabled={setDisabled} />
                    <Button onClick={(row) => updateSubscription(row)} variant="contained" sx={{ width: "100%" }} disabled={disabled}>UPDATE</Button>
                </RightDrawer>
                {
                    tableHeaderCells && (
                        <CustomTable
                            //Required props
                            tableConfig={tableConfig}
                            tableName={TableNames.RAPBACK_MAINTENANCE}
                            loading={loading}
                            rows={data?.length ? data : []}
                            orderBy={orderBy}
                            order={sortOrder}
                            totalRecords={totalRecords}
                            currentPage={page}
                            pageSize={rowsPerPage}
                            searched={searched}
                            value={value}
                            handleRequestSort={handleRequestSort}
                            setValue={setValue}
                            handleClickShowSSN={handleClickShowSSN}
                            displaySSN={displaySSN}
                            setSearched={setSearched}
                            //Per page props
                            defaultSortColumn="subscriptionDate"
                        />
                    )
                }

                <ConfirmModal
                    customWidth={'600px'}
                    customHeight={'193px'}
                    title={confirmModalTitle}
                    content={confirmModalContent}
                    cancelButtonText={cancelButtonText}
                    confirmButtonText={confirmButtonText}
                    handleCancel={() => setOpenConfirmModal(false)}
                    handleConfirm={async (e) => {
                        switch (action) {
                            case "unsubscribe":
                                setOpenConfirmModal(false)
                                const resp = await handleUnsubscribe(internalrapbackId)
                                addAuditEvent("Subscription Cancellation Request Submitted", "Rapback Maintenance", new Date(), internalrapbackId)

                                break;
                            /*case "edit_subscription":
                                handleOpenEditSubscriptions()
                                break;*/
                        }
                    }}
                    openDialog={openConfirmModal}
                />
            </Paper>{ }
        </>
    )
}


