/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import { useState, useEffect } from 'react';
import { urls } from '../urls'
import { ApplicantFetchStatus } from '@hookTypes/index';

const myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json');

export const useMugshots = (responseAssociationData: any[]) => {
    const [applicantImageUrls, setApplicantImageUrls] = useState<any>();
    let applicantImageUrlList: {id: string, urls: string[]}[] = [];

    const transactionUrl = urls.TRANSACTION;
    const controller = new AbortController();
    useEffect(() => {
        if (responseAssociationData) {
            const { signal } = controller;
            const fetchApplicantData = async () => {
                for (const response of responseAssociationData) {
                    try {
                        const portraitData = await (await fetch(`${process.env.NODE_ENV === 'production' ? transactionUrl : ""}/portrait/${response.id}`, {
                            method: 'GET',
                            credentials: "include",
                            signal
                        })).json();
                        const portraitDataUrl = portraitData.urls ?? [];
                        applicantImageUrlList.push({id: response.id, urls: portraitDataUrl})
                    } catch (err) {
                        console.error(err);
                    }
                }
                setApplicantImageUrls(applicantImageUrlList);
            }
            fetchApplicantData();
        }

        return () => controller.abort()

    }, [responseAssociationData, transactionUrl]);

    return { applicantImageUrls };
}
