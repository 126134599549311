import { OriGroup, userManagementColumnFilters, UserRow, UserStatus } from "../interfaces/hooks"
import { v4 as uuid } from 'uuid'
import { CognitoUser, OriObject, SoriObject, User } from "@apollo/user-wrapper";
import dayjs from 'dayjs';

const getOriRfpGroups = (oris?: OriObject[], soris?: SoriObject[] ) => {
    const oriRfpGroups: Array<OriGroup> = []
    if (oris?.length) {
        oris.map((oriObject: OriObject, index) => {
            if (oriObject?.rfp?.length && oriObject?.ori) {
                oriObject.rfp.map((rfp: string) => {
                    oriRfpGroups.push({
                        index,
                        primaryOri: {
                            assigned: true,
                            field: "Primary Ori",
                            value: oriObject.ori
                        },
                        rfp: {
                            assigned: true,
                            field: "RFP",
                            value: rfp
                        }
                    })
                })
            }
        });
    } if (soris?.length) {
        soris.map((soriObject: SoriObject, index) => {
            if (soriObject?.rfp?.length && soriObject?.sori) {
                soriObject?.rfp.map((rfp: string) => {
                    oriRfpGroups.push({
                        index,
                        secondaryOri: {
                            assigned: true,
                            field: "Secondary Ori",
                            value: soriObject.sori
                        },
                        rfp: {
                            assigned: true,
                            field: "RFP",
                            value: rfp
                        }
                    })
                })
            }
        });
    }
    return oriRfpGroups
}

const filterDuplicateRfps = (reasonFingerprinted?: string[][]) => {
    const filteredList = new Set<string>();
    const allRfps = reasonFingerprinted?.flatMap(rfp => rfp);
    if ( allRfps?.length ) {
        allRfps.map((rfp: string) => {
            if (!filteredList?.has(rfp)) {
                filteredList.add(rfp)
            }
        });
    }
    return Array.from(filteredList);
}

export const usersTransform = (cognitoUser: User, cognitoConfig: any, searchParams?: [{ column: string, value: string }]) => {
    const userJsonData: CognitoUser = cognitoUser?.jsonData;
    const userInformation = userJsonData?.userInfo;
    const accessInformation = userJsonData?.access;
    const configGroupList = cognitoConfig?.map((group: { value: string, label: string, isAdmin: boolean }) => group?.value);
    const caresGroup = configGroupList?.filter(group => accessInformation?.groups?.includes(group));
    // indexing at 0 is appropriate here as long as users can only have one cares group assigned
    const securityLevel = cognitoConfig?.filter((group: { value: string, label: string, isAdmin: boolean }) => group?.value === caresGroup[0]);
    
    const primaryOri: string[] | undefined = accessInformation?.oris?.map((oriObject: OriObject) => {
        return oriObject?.ori ?? []
    });
    const secondaryOri: string[] | undefined = accessInformation?.soris?.map((soriObject: SoriObject) => {
        return soriObject?.sori ?? []
    });
    const reasonFingerprinted: string[][] | undefined = accessInformation?.oris?.map((oriObject: OriObject) => {
        return oriObject?.rfp ?? []
    });
    const fullName = (userInformation?.firstName && userInformation?.lastName) ? userInformation.firstName + ' ' + userInformation.lastName : '';

    const row: UserRow = {
        key: uuid(),
        fullName,
        loginName: cognitoUser?.userId,
        securityLevel: securityLevel[0]?.label,
        status: userJsonData?.enabled ? UserStatus.ACTIVE : UserStatus.INACTIVE,
        emailAddress: userInformation?.email,
        primaryOri,
        secondaryOri,
        reasonFingerprinted: filterDuplicateRfps(reasonFingerprinted),
        lastModified: dayjs(cognitoUser?.lastModified?.replaceAll('\"', '')).format('MMM D, YYYY'),    
        oriRfpGroups: getOriRfpGroups(accessInformation?.oris, accessInformation?.soris),
        loginStatus: userInformation?.userStatus,
        user: cognitoUser
    };

    if (searchParams && searchParams.length > 0) {
        let columnFilter = searchParams[0].column
        let columnValue = searchParams[0].value
        if (columnFilter) {
            switch (columnFilter) {
                case (userManagementColumnFilters.SecurityLevel):
                    if (row.securityLevel?.toLowerCase().includes(columnValue?.toLowerCase())) {
                        return row
                    } 
                    break;
                case (userManagementColumnFilters.Status):
                    if (row.status?.toLowerCase() === columnValue?.toLowerCase()) {
                        return row
                    } 
                    break;
                case (userManagementColumnFilters.PrimaryOri):
                    if (row[columnFilter]?.join(' ').toLowerCase().includes(columnValue?.toLowerCase())) {
                        return row
                    } 
                    break;
                case (userManagementColumnFilters.ReasonFingerprinted):
                    if (row[columnFilter]?.join(' ').toLowerCase().includes(columnValue?.toLowerCase())) {
                        return row
                    } 
                    break;
                default:
                    if (row[columnFilter]?.toLowerCase().includes(columnValue.toLowerCase())) {
                        return row
                    } 
                    break;
            }
        }
    } else {
        console.log(`debug fetch users outter returning row: `, row)
        return row
    }
}

export const filterStatus = (status: string) => {
    switch (status) {
        case (UserStatus.ACTIVE):
            return true;
        case (UserStatus.INACTIVE):
            return false;
        default:
            return false;
    }
}

export const getUserGroup = (newGroup: string, cognitoConfig: any) => {
    const securityLevel = cognitoConfig?.filter((group: { value: string, label: string, isAdmin: boolean }) => group?.value?.toLowerCase() === newGroup?.toLowerCase());
    return securityLevel[0]?.label
}