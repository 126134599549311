import {
    Box,
    Typography,
    Button,
    Modal,
    TextField,
    MenuItem,
    Grid,
} from '@mui/material'
import { USSTATES } from "../helpers/USStates";
import { AddNewAgencyProps } from "@propTypes/index"
import { AgencyManagementStyles } from 'customStyles';
import { mapAgencyToApi, titleToNameMap } from '../helpers'
import { useCallback } from 'react'
import { useAgencyManagementStore } from '../helpers/AgencyManagementStore';
import { useAgencyManagement } from 'hooks/useAgencyManagement';
import { distributionMethodOptions } from '../helpers';
import { v4 as uuid } from 'uuid'

let lowerSliceIndex = 4
let midSliceIndex = 5
let upperSliceIndex = 9

export const AddNewAgencyModal: React.FC<AddNewAgencyProps> = ({
    tableHeaderCells,
    handleTextFieldError,
    handleCancel
}) => {
    // Agency Management State
    const data = useAgencyManagementStore(state => state.data);
    const setData = useAgencyManagementStore(state => state.setData);
    const newAgencyInfo = useAgencyManagementStore(state => state.newAgencyInfo);
    const setNewAgencyInfo = useAgencyManagementStore(state => state.setNewAgencyInfo);
    const setNewAgencyForm = useAgencyManagementStore(state => state.setNewAgencyForm);
    const newAgencyForm = useAgencyManagementStore(state => state.newAgencyForm);
    const setError = useAgencyManagementStore(state => state.setError);
    const setTouchFields = useAgencyManagementStore(state => state.setTouchFields);
    const touchedFields = useAgencyManagementStore(state => state.touchedFields);
    const setOriExists = useAgencyManagementStore(state => state.setOriExists);
    const oriExists = useAgencyManagementStore(state => state.oriExists);
    const setRfpExists = useAgencyManagementStore(state => state.setRfpExists);
    const rfpExists = useAgencyManagementStore(state => state.rfpExists);
    const setAlreadyExistError = useAgencyManagementStore(state => state.setAlreadyExistError);
    const alreadyExistError = useAgencyManagementStore(state => state.alreadyExistError);
    const error = useAgencyManagementStore(state => state.error);
    const fieldError = useAgencyManagementStore(state => state.fieldError);
    const openModal = useAgencyManagementStore(state => state.openModal);
    const setOpenModal = useAgencyManagementStore(state => state.setOpenModal);
    const canAddAgency = useAgencyManagementStore(state => state.canAddAgency);
    
    const addFields = tableHeaderCells?.map(cell => cell.dataIndex)

    const { createAgency } = useAgencyManagement()

    if (addFields?.includes("secondaryOri")) {
        lowerSliceIndex = 5
        midSliceIndex = 5
        upperSliceIndex = 10
    }

    const handleInputChange = ((index: any, value: any) => {
        console.log('index', index)
        if (value !== '') {
            if (index === 'agencyEmail') {
                const emails = value.split(",").map((email: string) => email.trim());
                setNewAgencyInfo({
                    ...newAgencyInfo,
                    agencyEmail: emails
                });
            } else {
                setNewAgencyInfo({
                    ...newAgencyInfo,
                    [index]: value
                })
                if (index in newAgencyForm) {
                    setNewAgencyForm({
                        ...newAgencyForm,
                        [index]: true
                    });
                }
            }
            setTouchFields({})
            setError("");
        } else {
            if (index in newAgencyForm) {
                setNewAgencyForm({
                    ...newAgencyForm,
                    [index]: false
                })
            }
            delete newAgencyInfo[index]
        }
        data?.map((agency: any) => {
            if (agency.ori !== newAgencyInfo["ori"]) {
                setOriExists(false)
            }
            if (agency.reasonFingerPrinted !== newAgencyInfo["reasonFingerPrinted"]) {
                setRfpExists(false)
            }
        })
        if (!rfpExists && !oriExists) setAlreadyExistError('');
    });

    const submitNewAgency = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const mappedAgency = mapAgencyToApi(newAgencyInfo);
            setData([newAgencyInfo, ...data]);
            let id = uuid();
            console.log("debeug add email edit/save update: ", mappedAgency);
            const resp = await createAgency(id, mappedAgency)
            console.log('Debug adding new agency response: ', resp);
            setOpenModal(false);
        } catch (error) {
            console.log("Creating New Agency Failed:", error);
        }
        setNewAgencyInfo({});
    }, [newAgencyInfo])

    console.log('newAgencyInfo', newAgencyInfo)

    return (
        <Modal open={openModal} onClose={handleCancel}>
            <Box sx={AgencyManagementStyles.addAgencyModalBoxStyle}>
                <Typography id='add-new-agency-modal-title' variant='h6' color='text.primary' sx={{ pt: '1.5rem', pb: '0.5rem' }}>
                    ADD NEW AGENCY
                </Typography>
                <form style={{ paddingTop: '1rem' }} onSubmit={submitNewAgency} key="add">
                    <Grid container spacing={2} sx={{ mb: '1.5rem' }}>
                        {addFields?.slice(0, lowerSliceIndex).map((fieldName: any, index: any) => {
                            const isError = fieldName.includes('ori') || fieldName.includes('reasonFingerPrinted')
                            return (
                                <Grid item xs={6} key={fieldName}>
                                    <TextField
                                        fullWidth
                                        key={fieldName}
                                        label={titleToNameMap[fieldName]}
                                        defaultValue={fieldName.includes('distributionMethod') ? '' : ''}
                                        helperText={touchedFields[fieldName] ? error : isError ? alreadyExistError : ''}
                                        error={touchedFields[fieldName] ? fieldError : isError ? (oriExists && rfpExists) : false}
                                        onBlur={(e) => handleTextFieldError(e, fieldName)}
                                        select={fieldName.includes('distributionMethod')}
                                        required={fieldName.includes('secondaryOri') ? false : true}
                                        onChange={(e) => handleInputChange(fieldName, e.target.value)}
                                    >
                                        {distributionMethodOptions.map((options: any) => {
                                            return (
                                                <MenuItem value={options.value || ""} key={options.value} sx={{ backgroundColor: 'background.default' }}>{options.value}</MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </Grid>
                            )
                        })}
                        {!addFields?.includes("secondaryOri") &&
                            <Grid item xs={12} >
                                <TextField
                                    fullWidth
                                    key={titleToNameMap['agencyEmail']}
                                    label={titleToNameMap['agencyEmail']}
                                    inputProps={{
                                        pattern: "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$",
                                    }}
                                    onChange={(e) => handleInputChange('agencyEmail', e.target.value)} />
                            </Grid>
                        }
                        {addFields?.slice(midSliceIndex, upperSliceIndex).map((fieldName: any, index: any) => {
                            return (
                                <Grid item xs={6} key={fieldName}>
                                    <TextField
                                        key={fieldName}
                                        onChange={(e) => handleInputChange(fieldName, e.target.value)}
                                        fullWidth
                                        helperText={touchedFields[fieldName] ? error : false}
                                        error={touchedFields[fieldName] ? fieldError : false}
                                        onBlur={(e) => handleTextFieldError(e, fieldName)}
                                        label={titleToNameMap[fieldName]}
                                        select={fieldName.includes('agencyState')}
                                        defaultValue={fieldName.includes('agencyState') ? '' : ''}
                                        required={fieldName.includes('agencyEmail') ? false : true}
                                        inputProps={fieldName.includes('agencyZipcode') ? {
                                            pattern: "[0-9]*",
                                            maxLength: 5
                                        } : {}}
                                        SelectProps={{
                                            MenuProps: {
                                                style: {
                                                    height: '28.125rem'
                                                }
                                            }
                                        }}
                                    >
                                        {USSTATES.map((value: any) => {
                                            return (
                                                <MenuItem key={value.value} value={value.value || ""} sx={{ overflowY: 'auto', backgroundColor: 'background.default' }} >
                                                    {`${value.value} - ${value.label}`}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </Grid>
                            )
                        })}
                        {/* <Grid item xs={12} >
                            <TextField fullWidth label={titleToNameMap['agencyStatus']} defaultValue='Active' select required disabled>
                                <MenuItem value='Active'>Active</MenuItem>
                                <MenuItem value='Inactive'>Inactive</MenuItem>
                            </TextField>
                        </Grid> */}
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', pb: '1rem' }}>
                        <Button variant='outlined' color='secondary' sx={{ color: 'text.primary', mr: '1rem' }} onClick={handleCancel}>
                            CANCEL
                        </Button>
                        <Button type="submit" variant='contained' color='secondary' disabled={(canAddAgency && !Boolean(error) && !alreadyExistError) ? false : true}>
                            ADD AGENCY
                        </Button>
                    </Grid>
                </form>
            </Box>
        </Modal>
    )
}