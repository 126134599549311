import {
    Drawer,
    Box,
    Typography,
    Button,
    TextField,
    MenuItem,
    Grid,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { useEffect, useState } from 'react'
import { USSTATES } from "../helpers/USStates";
import { EditAgencyProps } from '@propTypes/index';
import { AgencyManagementStyles } from 'customStyles';
import { mapAgencyToApi, titleToNameMap } from '../helpers'
import { useCallback } from 'react';
import { useAgencyManagementStore } from '../helpers/AgencyManagementStore';
import { useAgencyManagement } from 'hooks/useAgencyManagement';
import { distributionMethodOptions } from '../helpers';


export const EditAgencyDrawer: React.FC<EditAgencyProps> = ({
    tableHeaderCells,
    handleTextFieldError,
    handleCancel,
    currentAgency,
    loading
}) => {
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);
    const [isEdited, setIsEdited] = useState(false);
    const setData = useAgencyManagementStore(state => state.setData);
    const setOpenAgencyEdit = useAgencyManagementStore(state => state.setOpenAgencyEdit);
    const setError = useAgencyManagementStore(state => state.setError);
    const setTouchFields = useAgencyManagementStore(state => state.setTouchFields);
    const touchedFields = useAgencyManagementStore(state => state.touchedFields);
    const setSelectedRowText = useAgencyManagementStore(state => state.setSelectedRowText);
    const setFieldError = useAgencyManagementStore(state => state.setFieldError);
    const error = useAgencyManagementStore(state => state.error);
    const selectedRowText = useAgencyManagementStore(state => state.selectedRowText);
    const fieldError = useAgencyManagementStore(state => state.fieldError);
    const openAgencyEdit = useAgencyManagementStore(state => state.openAgencyEdit);    
    const { updateAgency } = useAgencyManagement();

    const editFields = tableHeaderCells?.map(cell => cell.dataIndex)
    
    useEffect(() => {
        const arraysEqual = (arr1, arr2) => {
            if (arr1.length !== arr2.length) return false;
            return arr1.every((element, index) => {
                if (element) {
                   return element.trim() === arr2[index].trim()
                } else {
                    return false
                }
            });
        };
        
        const isEdited = Object.keys(selectedRowText).some((key) => {
            if (key === "agencyEmail") {
                if (Array.isArray(selectedRowText[key]) && Array.isArray(currentAgency?.[key])) {
                    return !arraysEqual(selectedRowText[key], currentAgency[key]);
                } else {
                    return true;
                }
            } else {
                return currentAgency?.[key] !== selectedRowText[key];
            }
        });

        if (isEdited) {
            setIsEdited(true);
        } else {
            setIsEdited(false);
        }
    }, [selectedRowText]);


    useEffect(() => {
        const handleResize = () => {
            setScreenHeight(window.innerHeight);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const closeDrawer = () => {
        setOpenAgencyEdit(false)
        setSelectedRowText({});
        setError('');
        setIsEdited(false)
    }

    const handleEditInput = ((index: any, value: any) => {
        if (value !== '') {
            if (index === 'agencyEmail') {
                const emails = value.split(",");
                setSelectedRowText({
                    ...selectedRowText,
                    agencyEmail: emails
                });
            } else {
                setSelectedRowText({
                    ...selectedRowText,
                    [index]: value
                })
            }
            setTouchFields({});
            setFieldError(false);
            setError('');
        } else {
            if (index === 'agencyEmail') {
                setSelectedRowText({
                    ...selectedRowText,
                    agencyEmail: value
                })
            } else {
                setSelectedRowText({
                    ...selectedRowText,
                    [index]: value
                })
            }
        }
    })

    const submitEditedAgency = useCallback(async (e: any, updates: any, id: any, isDelete: boolean = false) => {
        e.preventDefault();
        try {
            const updatedMappedAgency = mapAgencyToApi(selectedRowText);
            console.log('debug updated mapped agency: ', updatedMappedAgency)

            let agencyId: string;
            if (selectedRowText["id"] === selectedRowText["ori"]) {
                agencyId = selectedRowText["ori"]
            } else {
                agencyId = selectedRowText["id"]
            }

            setData((prevData: any) => {
                return prevData.map((agency: any) => {
                    if (agency.id === id) {
                        return { ...agency, ...updates }
                    }
                    return agency
                })
            })
            const resp = await updateAgency(agencyId, updatedMappedAgency)
            console.log('Updating existing agency response: ', resp);

            setOpenAgencyEdit(false);
            console.log("debeug add email edit/save update: ", updatedMappedAgency);
        } catch (error) {
            console.log('Agency update failed: ', error);
        }
    }, [selectedRowText])

    return (
        <Drawer anchor='right' open={openAgencyEdit} onClose={closeDrawer} sx={{ zIndex: 5000 }}>
            <Box sx={AgencyManagementStyles.editAgencyModalBox}>
                <Grid container justifyContent='flex-end' alignItems='center'>
                    <Button size='small' startIcon={<CloseIcon />} sx={{ color: 'text.primary' }} onClick={closeDrawer}>CLOSE</Button>
                </Grid>
                <Typography sx={{ fontSize: '1.5rem', fontWeight: 500, mb: '1rem' }}>EDIT AGENCY</Typography>
                <SimpleBar style={{ height: screenHeight < 1000 ? '65vh' : '80vh' }} >
                    <form style={{ paddingTop: '1rem' }} onSubmit={(e) => submitEditedAgency(e, selectedRowText, selectedRowText["id"])}>
                        {editFields?.map((fieldName: any) => {
                            return (
                                <Grid item xs={12} sx={{ mb: '1.5rem' }} key={fieldName}>
                                    <TextField
                                        key={titleToNameMap[fieldName]}
                                        onChange={(e) => handleEditInput(fieldName, e.target.value)}
                                        helperText={touchedFields[fieldName] ? error : false}
                                        error={touchedFields[fieldName] ? fieldError : false}
                                        onBlur={(e) => handleTextFieldError(e, fieldName)}
                                        fullWidth
                                        label={titleToNameMap[fieldName]}
                                        disabled={(fieldName.includes('ori') || fieldName.includes('secondaryOri') || fieldName.includes('reasonFingerPrinted')) ? true : false}
                                        select={fieldName.includes('agencyState') || fieldName.includes('distributionMethod') || fieldName.includes('agencyStatus')}
                                        required={fieldName.includes('agencyEmail') || fieldName.includes('secondaryOri') ? false : true}
                                        value={selectedRowText?.[fieldName]}
                                        inputProps={fieldName.includes('agencyZipcode') ? {
                                            pattern: "[0-9]*",
                                            maxLength: 5
                                        } : {}}
                                        SelectProps={{
                                            MenuProps: {
                                                style: {
                                                    zIndex: 5500,
                                                    height: '28.125rem'
                                                }
                                            }
                                        }}
                                    >
                                        {fieldName.includes('distributionMethod') && distributionMethodOptions.map((options: any) => {
                                            return (
                                                <MenuItem key={options.value} value={options.value} sx={{ backgroundColor: 'background.default' }}>{options.value}</MenuItem>
                                            )
                                        })}
                                        {fieldName.includes('agencyState') && USSTATES.map((value: any) => {
                                            return (
                                                <MenuItem key={value.value} value={value.value} sx={{ overflowY: 'auto', backgroundColor: 'background.default' }} >
                                                    {`${value.value} - ${value.label}`}
                                                </MenuItem>
                                            )
                                        })}
                                        {/* {fieldName.includes('agencyStatus') &&
                                                <span>
                                                    <MenuItem key='Active' value='Active'>Active</MenuItem>
                                                    <MenuItem key='Inactive' value='Inactive'>Inactive</MenuItem>
                                                </span>   
                                            } 
                                        */}
                                    </TextField>
                                </Grid>
                            )
                        })}
                        {/* <Grid item xs={12} >
                            <TextField fullWidth label={titleToNameMap['agencyStatus']} defaultValue='Active' select required disabled>
                                <MenuItem value='Active'>Active</MenuItem>
                                <MenuItem value='Inactive'>Inactive</MenuItem>
                            </TextField>
                        </Grid> */}
                        <Grid item xs={12} sx={{ display: 'flex', mb: '1.5rem', mt: '1.5rem' }} bottom={0} position='fixed' width='36rem'>
                            <Button fullWidth variant='outlined' color='secondary' sx={{ mr: '1rem', color: 'text.primary' }} onClick={handleCancel} >CANCEL</Button>
                            <Button fullWidth type="submit" variant='contained' color='secondary' disabled={!isEdited ? true : fieldError ? true : loading ? true : false} >
                                UPDATE
                            </Button>
                        </Grid>
                    </form>
                </SimpleBar>
            </Box>
        </Drawer>
    )
}