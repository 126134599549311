import { create } from "zustand"
import { persist, createJSONStorage } from 'zustand/middleware'
import { TableNames } from 'customEnums/TableNames';
import { Config } from "interfaces/config";

export interface UserConfig {
    userRole: any
    userGroup: any
    usersPermissions: any
    groups: any
    userGivenName: any
    userFamilyName: any
    userEmail: any
    loggedIn: boolean,
    isAdmin: string | undefined
}

export interface UserState {
    userConfigLoaded: boolean | undefined
    userConfig: UserConfig,
    activeFilters: any,
    config: any,
    setUserConfig: (userConfig: UserConfig) => void
    setActiveFilters: (activeFilters: any) => void
    setConfig: (config: any) => void
    setUserConfigLoaded: (userConfigLoaded: boolean) => void
}

const initialState = {
    userConfig: {
        userRole: "",
        userGroup: "",
        usersPermissions: [],
        groups: "",
        userGivenName: "",
        userFamilyName: "",
        userEmail: "",
        loggedIn: false,
        isAdmin: undefined
    },
    activeFilters: {

        [TableNames.TRANSACTION_SUMMARY]: {
            filterValues: []
        }

    },
    config: {},
    userConfigLoaded: false,
}

export const useUserStore = create(
    persist(
        (set, get) => ({
            
            ...initialState,
            setUserConfigLoaded: (userConfigLoaded: boolean) => set({ userConfigLoaded }),
            setConfig: (config: Config) => set({ config }),
            setActiveFilters: (activeFilters: any) => set({ activeFilters }),
            setUserConfig: (userConfig: UserConfig) => set({ userConfig }),
            resetUserStore: () => set(initialState)
        }),
        {
            name: "userConfig",
            storage: createJSONStorage(() => sessionStorage),
            partialize: (state: any) => {
                return { userConfig: state.userConfig, config: state.config, userConfigLoaded: state.userConfigLoaded }
            },
            
            merge: (persistedState: any, currentState) => {
                return { ...currentState, ...persistedState }
            },
        }
    )
)