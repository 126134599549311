
import { ColumnStateActions } from "../../customEnums/ColumnState";

const setAllValues = (subState: any, value: boolean) => {
    Object.keys(subState).map((key: string) => {
        subState[key] = value
    })
}

const setAllFilterValues = (subState: any, value: boolean) => {
    Object.keys(subState).map((key: string) => {
        subState[key].selected = value
    })
}


export const columnVisibilityReducer = (state: any, action: any): any => {
    console.log('debug handleColumnUpdate columnStateReducer running STATE: ', state.columnState.page[action.page][action.tableName])
    console.log('debug handleColumnUpdate columnStateReducer running ACTION: ', action)

    const { columnVisibility, filters } = state.columnState.page[action.page][action.tableName]

    const selectAllFilter = state.columnState.page[action.page][action.tableName].filters?.map((filter) => {
        if (filter.filterColumn === action.column) {
            return {
              ...filter,
              filterValues: Object.keys(filter.filterValues).reduce((acc: any, key: string) => {
                acc[key] = {
                  ...filter.filterValues[key],
                  selected: true,
                };
                return acc;
              }, {}),
            };
        }
        return filter
      });

    const deselectAllFilters = state.columnState.page[action.page][action.tableName].filters?.map((filter) => {
        if (filter.filterColumn === action.column) {
            return {
              ...filter,
              filterValues: Object.keys(filter.filterValues).reduce((acc: any, key: string) => {
                acc[key] = {
                  ...filter.filterValues[key],
                  selected: false,
                };
                return acc;
              }, {}),
            };
        }
        return filter
      });

    switch (action.type) {
        case ColumnStateActions.SHOW_ALL_COLUMNS:
            console.log('debug columnState reducer debug in SHOW_ALL_COLUMNS action case state: ', state)
            setAllValues(columnVisibility, true)

            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName]
                            }
                        }
                    }
                }
            }

        case ColumnStateActions.SHOW_ALL_FILTERS:
            setAllFilterValues(filters, true)
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                filters: selectAllFilter
                            }

                        }
                    }
                }
            }

        case ColumnStateActions.HIDE_ALL_COLUMNS:
            setAllValues(columnVisibility, false)
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName]
                            }
                        }
                    }
                }
            }

        case ColumnStateActions.HIDE_ALL_FILTERS:
            console.log('filterStatus', filters);
            setAllFilterValues(filters, false)

            console.log('debug TableTools filter column reducer return: ', {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                filters: deselectAllFilters
                            }

                        }
                    }
                }
            }, )


            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                filters: deselectAllFilters
                            }

                        }
                    }
                }
            }

        case ColumnStateActions.RESET_COLUMNS:
            console.log(`debug TableTools filter column ${action.dataIndex} reducer reset ran`)
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                columnVisibility: {
                                    ...action.columnVisibility
                                }
                            }

                        }
                    }
                }
            }

        case ColumnStateActions.RESET_FILTERS:
            //reset is clearing table headers
            console.log('debug CustomTableHead tableHeaderCells reset action state: ', state)

            console.log('101011011010 debug reset filters return: ', {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                filters: [
                                    ...action.filterList
                                ]
                            }

                        }
                    }
                }
            })
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                filters: [
                                    ...action.filterList
                                ]
                            }

                        }
                    }
                }
            }

        case ColumnStateActions.TOGGLE_FILTER:
            
            
            const updatedFilters = state.columnState.page[action.page][action.tableName].filters?.map((filter) => {
                if (filter.filterColumn === action.column) {
                    return {
                        ...filter,
                        filterValues: {
                            ...filter.filterValues,
                            [action.filter]: {
                                ...filter.filterValues[action.filter],
                                selected: !filter.filterValues[action.filter].selected
                            }
                        }
                    };
                } else {
                    return filter;
                }
            });

            console.log(`debug handleColumnUpdate toggle filter column reducer return: `, {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                filters: updatedFilters
                            }

                        }
                    }
                }
            })

            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                filters: updatedFilters
                            }

                        }
                    }
                }
            }
        case ColumnStateActions.TOGGLE_COLUMN:
            console.log(`debug TableTools filter column ${action.dataIndex} reducer: `, columnVisibility)
            console.log(`debug TableTools filter column ${action.dataIndex}  reducer: `, filters)
            console.log(`debug TableTools filter column ${action.dataIndex}  reducer: `, action)
            console.log(`debug TableTools filter column ${action.dataIndex}  reducer return: `, {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                columnVisibility: {
                                    ...state.columnState.page[action.page][action.tableName].columnVisibility,
                                    [action.dataIndex]: !state.columnState.page[action.page][action.tableName].columnVisibility[action.dataIndex]
                                }
                            }

                        }
                    }
                }

            })
            return {
                ...state,
                columnState: {
                    ...state.columnState,
                    page: {
                        ...state.columnState.page,
                        [action.page]: {
                            ...state.columnState.page[action.page],
                            [action.tableName]: {
                                ...state.columnState.page[action.page][action.tableName],
                                columnVisibility: {
                                    ...state.columnState.page[action.page][action.tableName].columnVisibility,
                                    [action.dataIndex]: !state.columnState.page[action.page][action.tableName].columnVisibility[action.dataIndex]
                                }
                            }

                        }
                    }
                }

            }

    }

}