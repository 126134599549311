import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import {
    Button,
    Container,
    TextField,
    Autocomplete,
    Badge,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { CustomTableStyle } from 'customStyles/CustomTableStyle';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomSearchProps } from "@propTypes/index";
import { MenuState } from 'customEnums/MenuState';
// import { useConfig } from 'hooks/useConfig';
import { useTableStore } from 'state/TableStore';
import { SavedSearchActionTypes } from 'customEnums/TransactionSummaryEnums';
import { CustomPaper } from './CustomPaper';
import { SearchParam, SearchParams } from '@customTypes/SearchParam';
import { savedSearchesReducer } from "../../../pages/TransactionSummary/helpers/savedSearchesReducer";
import { getCurrentEnv, LogConfig } from "../../../utils";
import { HeaderCell } from 'interfaces/config';


export const SimpleSearch: React.FC<CustomSearchProps> = ({
    value,
    tableName,
    setValue,
    label,
    advancedSearchNumParams,
    setCreateSearch,
    setDisplaySavedMessage,
    datePlaceHolder,
    setDatePlaceholder,
    menuState,
    setMenuState,
    useQueryPagination,
    tableConfig
    
}) => {
    const pageName = getCurrentEnv

    console.log('debug tableConfig in SimpleSearch: ', tableConfig)
    const { advancedSearch, tableHeaderCells } = tableConfig![tableName!]

    //let tableHeaderCells = tables.find((headerCell: HeaderCell) => headerCell.tableName === tableName)?.tableHeaderCells!

    const [allowSearch, setAllowSearch] = useState<boolean>(false)
    let searchCategories: any = []
    searchCategories = tableHeaderCells?.map((cell: any) => { return { label: cell.label, dataIndex: cell.dataIndex } }).filter((obj: any) => 
        obj.label !== "Actions" && obj.label !== "Last Viewed Date" && obj.label !== "Is Hit" && obj.label !== "Time Remaining"
    )

    const [menuItems, setMenuItems] = useState(searchCategories)
    const [inputValueCleared, setInputValueCleared] = useState(false)
    const [internalSearchParams, setInternalSearchParams] = React.useState<any>([]); //Used to internal manage search parameters before sending parameter to parent component
    const [inputValue, setInputValue] = React.useState('');
    // Table Store States:
    const setSearchParams = useTableStore((state) => state.setSearchParams)
    const searchParams = useTableStore((state) => state.searchParams)

    const simpleSearchRan = useTableStore((state) => state.simpleSearchRan)
    const setSimpleSearchRan = useTableStore((state) => state.setSimpleSearchRan)

    // const [isPost, setIsPost] = useState<boolean>(false);
    // const [isDelete, setIsDelete] = useState<boolean>(false);
    // const [configFile, setConfigFile] = useState('alert-closed.json');
    // const [configBody, setConfigBody] = useState({});


    // const advancedSearch = useTableStore(state => state.advancedSearch)
    const dispatchFormValues = useTableStore((state) => state.dispatchFormValues)
    const resetTable = useTableStore(state => state.resetTable)
    const pagination = useTableStore(state => state.pagination)
    const setPagination = useTableStore(state => state.setPagination)
    const configService = useTableStore(state => state.configService)
    const setConfigService = useTableStore(state => state.setConfigService)
    const setIsPost = useTableStore((state) => state.setIsPost)
    const setIsDelete = useTableStore((state) => state.setIsDelete)
    const setConfigFile = useTableStore((state) => state.setConfigFile)
    const setConfigBody = useTableStore((state) => state.setConfigBody)
    const internalSearchArray = useTableStore(state => state.internalSearchArray)
    const setInternalSearchArray = useTableStore(state => state.setInternalSearchArray)

    const { searchData } = configService
    console.log('debug SavedSearchActionType searchData: ', searchData)
    const loadedData = useMemo(() => ({ ...searchData }), [searchData]);
    const [savedSearches, dispatchSearch] = useReducer(savedSearchesReducer, loadedData);

    const [selectedColumn, setSelectedColumn] = useState()


    useEffect(() => {
        if (searchData && advancedSearch) {
            dispatchSearch?.({
                type: SavedSearchActionTypes.LOAD_SEARCHES,
                searchData,
                configService,
                setters: {
                    setIsDelete,
                    setConfigFile,
                    setConfigBody,
                    setIsPost,
                    setConfigService,
                    setInternalSearchArray
                }
            })
            setConfigService({ ...configService, postedConfig: false })
            setConfigFile('sticky-settings.json')
            setInternalSearchArray(searchData)
        }

        if (!advancedSearch) {
            setInternalSearchArray({})
            dispatchSearch?.({ type: SavedSearchActionTypes.UNLOAD_SEARCHES})
        }
    }, [loadedData, searchData, advancedSearch, dispatchSearch]);

    useEffect(() => {
        if (menuState === MenuState.VALUE || menuState === MenuState.SAVED_SEARCH) {
            setMenuItems([]);
        } else if (menuState === MenuState.CATEGORY) {
            let searchList: any = [];
            // if the user has saved searches, set the last one to have displayDivider = true and add it to the list of search categories.
            if (internalSearchArray[0]) {
                let index = 0;
                for (const search in internalSearchArray) {
                    if (index === Object.keys(internalSearchArray).length - 1) {
                        internalSearchArray[search].displayDivider = true
                    } else {
                        delete internalSearchArray[search]?.displayDivider
                    }
                    searchList.push(internalSearchArray[search]);
                    index++;
                }
            }
            // fully populate the search dropdown with search categories
            for (const item in searchCategories) {
                searchList.push(searchCategories[item])
            }
            searchCategories?.length > 0 && setMenuItems(searchList);
        }
        // Removing until different search operations are supported in query service
        // } else if (menuState === MenuState.OPERATOR) {
        //     setMenuItems(searchOperators)
        // }
    }, [menuState, searchCategories?.length, internalSearchArray])

    const pageLabel = ["user-management", "transaction-summary", 'rapback-summary', 'agency-management', 'batch-printing', "rapback-maintenance", "rapback-validations", "rapback-prenotifications"];

    useEffect(() => {
        if (pageLabel.includes(label)) {
            if (inputValue && value.length > 0) {
                const params: SearchParams = []
                const param: SearchParam = {
                    column: value[0].dataIndex,
                    value: inputValue
                }

                params.push(param)
                setInternalSearchParams(params)
                setSimpleSearchRan(true)

                return
            } else if ((!inputValue && !inputValueCleared && value.length <= 0 && simpleSearchRan) || (typeof value[0] === "string")) {
                resetTable()
                setSimpleSearchRan(false)
                setMenuState(MenuState.CATEGORY)
                return
            } else if (!inputValue && inputValueCleared && value.length <= 0 && simpleSearchRan) {
                //Reset simple search state without searching for transactions again
                setSimpleSearchRan(false)
                setMenuState(MenuState.CATEGORY)
                return
            } else if (inputValueCleared && simpleSearchRan && value.length <= 0) {
                if (value.length > 0) {
                    if (value[0].dataIndex) {
                        setSimpleSearchRan(false)
                        setMenuState(MenuState.CATEGORY)
                        setSearchParams([{ column: value[0].dataIndex, value: "" }])
                    }
                }
            }
        }
    }, [value, inputValue, inputValueCleared])

    const handleChange = (e: any, newValue: any) => {
       
        setSelectedColumn(newValue[0]?.dataIndex)
        
        const datePlaceHolderIndexes = ["transactionDate", "insertDate", "expirationDate"]
        if ((datePlaceHolderIndexes.includes(newValue[0]?.dataIndex)) && !simpleSearchRan) {
            setDatePlaceholder(true)
        }
        // In the case of a saved search, do some object manipulation to get the result into a format that is readable by useQueryTransaction
        if (newValue[0]?.searchParams) {
            let tempColumn = '';
            let tempValue = '';
            let tempArray: SearchParams = [];
            //set states properly so the new value and menu state propogate through rest of the code
            setValue(newValue);
            setMenuState(MenuState.SAVED_SEARCH);
            // using the keys as index, add the correctly formatted object to the search params array
            Object.keys(newValue[0]?.searchParams).map(key => {
                tempColumn = key;
                tempValue = newValue[0]?.searchParams[key]
                tempArray.push({ column: tempColumn, value: tempValue })
            })
            setSearchParams(tempArray);
            if (setSearchParams) {
                // conduct the saved search when it gets clicked from the autcomplete dropdown
                setSimpleSearchRan(true);
                setSearchParams(tempArray);
            }
        } else {
            if (newValue[0]) {
                setValue?.(newValue)
                setMenuState(MenuState.VALUE)
            } else {
                setMenuState(MenuState.CATEGORY)
                setDatePlaceholder(false)
                resetTable?.()
                setValue?.(newValue)
                return
            }
        }

        //On value chip close update search parameters in parent to research with existing column and empty value
        if (newValue.length > 0) {
            if (newValue[0].hasOwnProperty("label") && newValue.length === 1 && simpleSearchRan) {

                if (newValue[0].dataIndex !== "transactionDate") {
                    setSearchParams([{ column: newValue[0].dataIndex, value: "" }])
                    setInternalSearchParams([{ column: newValue[0].dataIndex, value: "" }])
                } else {
                    setDatePlaceholder(true)
                    setSearchParams([])
                    setInternalSearchParams([{}])
                }
                setInputValueCleared(true)

            }
        }
    }

    const handleInputChange = (e, newInputValue: any) => {
        setInputValue(newInputValue);
        setInputValueCleared(false);
    }

    const handleExecuteSearch = async (tableName: any) => {
        console.log(`debug useSetConfig in pagination useEffect pagination: `, pagination)
        setPagination({ ...pagination, [tableName]: { ...pagination[tableName], page: useQueryPagination ? 1 : 0 } })
        setSearchParams(internalSearchParams)
        setSimpleSearchRan(true) //removed t fix simple search, and stop custom table from rendering, it looks like we were setting date place holder to false when this was updated. if this is an issue we may need to refactor.
        setDatePlaceholder(false)
    }

    const renderGroups = useCallback((props: any, option: any) => {
        console.log('debug useConfigService renderGroups: ', { option, advancedSearch, searchData, loadedData })
        if (option.dataIndex) {
            return (
                <ListItem key={option.dataIndex} {...props}>
                    <ListItemText primary={option.label} />
                </ListItem>
            )
        } else if (!option.dataIndex && advancedSearch) {
            return (
                <>
                    <ListItem key={option.label} {...props}>
                        <ListItemText primary={option.label} />
                        <ListItemIcon>
                            <IconButton edge='end' onClick={(e: any) => {
                                e.stopPropagation();
                                dispatchSearch?.({
                                    type: SavedSearchActionTypes.DELETE_SEARCH,
                                    searchName: option.label,
                                    setters: {
                                        setIsDelete,
                                        setConfigFile,
                                        setConfigBody,
                                        setIsPost,
                                        setInternalSearchArray
                                    }
                                });
                               
                            }}>
                                <DeleteIcon sx={{ display: 'flex', alignItems: 'flex-start' }} />
                            </IconButton>
                        </ListItemIcon>
                    </ListItem>
                    {option.displayDivider && <Divider />}
                </>
            )
        }
    }, [searchData, loadedData, menuItems, advancedSearch, dispatchSearch])

    //set conditional vars to all handleExecuteSearch to run
    useEffect(() => {
        if (internalSearchParams.length > 0) {
            if (internalSearchParams[0].hasOwnProperty("value") && internalSearchParams[0].hasOwnProperty("column")) {
                setAllowSearch(true)
            }
        }
    }, [internalSearchParams])

    return (
        <Container disableGutters maxWidth={false} sx={{ width: "600px", display: "flex", alignItems: "center", gap: '0.5rem' }}>
            <Autocomplete
                onChange={handleChange}//Handler For Column
                value={value}//Column to search
                multiple
                inputValue={inputValue}//value to search
                onInputChange={searchParams.length > 0 && searchParams[0].value !== "" ? () => { } : handleInputChange}//Handler for value
                freeSolo
                id={label + "-id"}
                options={menuItems || []}
                PaperComponent={CustomPaper}
                renderOption={renderGroups}
                onKeyDown={(event) => {
                    if (event.key === "Enter" && allowSearch) {
                        handleExecuteSearch(tableName)
                    }
                }}
                renderInput={(params) => <TextField {...params} sx={CustomTableStyle.searchStyle} id="select-multiple-chip" placeholder={!simpleSearchRan && ( datePlaceHolder || selectedColumn === "dob" )  ? "MM / DD / YYYY - MM / DD / YYYY" : simpleSearchRan ? "" : "Full Name, State TCN"} label="Search" />}
            />
            {
                advancedSearch && (
                    <Badge color="primary" badgeContent={advancedSearchNumParams}>
                        <Button onClick={() => { setDisplaySavedMessage(false); setTimeout(() => { setCreateSearch?.(true); }, 1000) }}
                            color="primary"
                            variant='text'
                            sx={CustomTableStyle.searchButtonStyle}
                            startIcon={<AddCircleOutlineIcon />}>
                            ADVANCED SEARCH
                        </Button>
                    </Badge>
                )
            }
        </Container>
    )
}