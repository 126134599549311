import { Box } from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

export const tabProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
export const CustomTabPanel: React.FC<TabPanelProps> = ({children, index, value, ...other}) => {
    return (
      <div
        style={{height: "100%"}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{height: "100%"}}>
            {children}
          </Box>
        )}
      </div>
    );
}