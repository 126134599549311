export const nistFieldParsing = (nistParsing: any, applicantData: any) => {
    const data: Record<string, any> = {};
    const type1 = applicantData["descriptors"].data.type1;
    const type2 = applicantData["descriptors"].data.type2;

    nistParsing.fields.forEach((field: any) => {
        const { name, key, suffixMapping, type, value, position } = field;
        let fieldData;

        if (value && typeof position === "number") {
            if (type === 1) {
                fieldData = type1[key]?.[position]?.[value];
            } else {
                fieldData = type2[key]?.[position]?.[value];
            }
        } else {
            if (type === 1) {
                fieldData = type1[key];
            } else {
                fieldData = type2[key];
            }
        }

        if (suffixMapping && name === "suffix" && fieldData) {
            fieldData = suffixMapping[fieldData] || fieldData;
        }

        data[name] = fieldData;
    });
    return data;
};