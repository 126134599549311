import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material'
import { urls } from "../../../urls";
import { useMUITheme } from '../../../theme/ThemeProvider';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Order } from '@customTypes/OrderType';
import { PrintModalHeaderCells } from '../helpers';
import { BatchPrintingStyles } from 'customStyles';
import { useTableStore } from 'state/TableStore';
import { useAudit } from '../../../hooks/useAudit';
import { useBatchPrint } from 'hooks';

enum BatchPrintOk {
    OK = "Successfully updated all the tcns confirmedate"
}

export const PrintModal: React.FC<any> = ({ label, rows, handleClose, open, handleOpenPrint }) => {
    const url = urls.BATCHPRINT;
    const [tcnResp, setResp] = useState<any>({})
    const [submissionSuccessful, setShowSubmissionSuccessful] = useState(false)

    const [pdfAvailable, setPdfAvailable] = useState(false)
    const [presignedS3Url, setPreSignedUrl] = useState("")
    const [percentComplete, setPercentComplete] = useState(0)
    const [numJobStatusCalls, setNumJobStatusCalls] = React.useState(0)
    const { mode } = useMUITheme();
    const [remaining, setRemaining] = useState<any>(10 * 60_000);
    const [activeTimer, setActiveTimer] = useState<boolean>(false)
    const [hoverLabel, setHoverLabel] = useState("")
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [defaultSortColumn, setDefaultSortColumn] = useState("transactionDate")
    const [sortOrder, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState(defaultSortColumn);
    const headerButtonConfig = useTableStore(state => state.headerButtonConfig)
    const tcns = useTableStore(state => state.tcns)
    const setTcns = useTableStore(state => state.setTcns)
    const selected = useTableStore(state => state.selected)
    const setSelected = useTableStore(state => state.setSelected)
    const setHeaderButtonConfig = useTableStore(state => state.setHeaderButtonConfig)
    const setOpenPrint = useTableStore(state => state.setOpenPrint)
    const searchParams = useTableStore(state => state.searchParams)
    const { addAuditEvent } = useAudit()
    //const { rowsPerPage, page, sortOrder, orderBy, searchParams } = batchPrintParams
    const { handlePrintConfirm } = useBatchPrint(rowsPerPage, page, sortOrder, orderBy, searchParams)
    const setSearchParams = useTableStore(state => state.setSearchParams)

    let jobStatusController = new AbortController()
    let checkPdfController = new AbortController()

    useEffect(() => {
        console.log('batch print confirm tcns PrintModal ', tcns)
        let selected: any = []
        tcns.forEach((record: any) => selected.push(record.transactionNumber))
        console.log('batch print flow set selected: ', selected)
        setSelected(selected)
    }, [tcns.length])

    const [batchPrintSteps, setBatchPrintSteps] = useState(
        {
            stepA_Confirm: true,
            stepB_PreparePdf: false,
            stepC_DocumentReady: false,
            stepD_Confirm: false,
            cancelConfirmPrint: false,
            cancelConfirmError: false,
            docExpired: false,
            pdfGenError: false,
            confirmingPrintJobs: false,
        })

    console.log('batch print flow printModal rows:  ', JSON.stringify(rows))
    console.log('printModal tcns', tcns)

    const createSortHandler = (property: any) => (event: any) => {
        console.log(`sort date createSortHandler property ${property} e }`, event.target.checked)
        if (event.target.checked !== true && event.target.checked !== false) {
            handleRequestSort(event, property);
        }
    };

    const handleRequestSort = (event: any, property) => {

        let currentProperty = property != "fullName" ? property : "firstName"
        const isAsc = orderBy === currentProperty && sortOrder === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');

        setOrderBy(currentProperty);
    };

    function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        console.log('stableSort stablizedThis: ', stabilizedThis)
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        console.log('stableSort return: ', stabilizedThis.map((el) => el[0]))
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string },
    ) => number {
        console.log('getComparator running orderBy: ', orderBy)
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    const visibleRows = React.useMemo(
        () =>
            stableSort(tcns, getComparator(sortOrder, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [sortOrder, orderBy, page, rowsPerPage],
    );


    const handleContinueErrorConfirm = () => {
        setBatchPrintSteps({
            stepA_Confirm: true,
            stepB_PreparePdf: false,
            stepC_DocumentReady: false,
            stepD_Confirm: false,
            cancelConfirmPrint: false,
            cancelConfirmError: false,
            docExpired: false,
            pdfGenError: false,
            confirmingPrintJobs: false,
        })
    }

    const handleContinuePrintConfirm = () => {
        setBatchPrintSteps({
            stepA_Confirm: false,
            stepB_PreparePdf: false,
            stepC_DocumentReady: false,
            stepD_Confirm: true,
            cancelConfirmPrint: false,
            cancelConfirmError: false,
            docExpired: false,
            pdfGenError: false,
            confirmingPrintJobs: false
        })
    }

    const handleCancelStepA = () => {
        setBatchPrintSteps({
            stepA_Confirm: true,
            stepB_PreparePdf: false,
            stepC_DocumentReady: false,
            stepD_Confirm: false,
            cancelConfirmPrint: false,
            cancelConfirmError: false,
            docExpired: false,
            pdfGenError: false,
            confirmingPrintJobs: false
        })
        handleClose()
    }

    const handleCancel = () => {
        jobStatusController.abort()
        checkPdfController.abort()
        setBatchPrintSteps({
            stepA_Confirm: false,
            stepB_PreparePdf: false,
            stepC_DocumentReady: false,
            stepD_Confirm: false,
            cancelConfirmPrint: !batchPrintSteps.pdfGenError,
            cancelConfirmError: batchPrintSteps.pdfGenError,
            docExpired: false,
            pdfGenError: false,
            confirmingPrintJobs: false
        })
    }

    const handleSubmitTcns = async () => {
        setBatchPrintSteps((prevSteps: any) => { return { ...prevSteps, stepA_Confirm: false, stepB_PreparePdf: true } })
        const body = {
            inputTcns: tcns.map((row: any) => row.transactionNumber)
        }

        try {
            let { message, batchPrintJob } = await (await (fetch(`${process.env.NODE_ENV === "production" ? url : ""
                }/batchprint/tcns-submission`, {
                method: 'POST',
                body: JSON.stringify(body),
                credentials: 'include'
            }))).json()

            setResp({ message, batchPrintJob })
        } catch (e: any) {
            console.error('Error submmitinc tcns: ', e)
        }

    }

    const getPdfUrl = async () => {
        console.log('batch print flow getPdfUrl running')
        const bodyObj = {
            batchPrintJob: tcnResp.batchPrintJob
        }
        let body = JSON.stringify(bodyObj)
        try {
            let resp = await (await (fetch(`${process.env.NODE_ENV === "production" ? url : ""
                }/batchprint/presigned-url`, {
                method: 'POST',
                body,
                credentials: 'include'
            }))).json()

            console.log('batch print flow getPdfUrl resp: ', resp)
            const { status } = resp
            if (status === "Processing") {
                setTimeout(() => {
                    getPdfUrl()
                }, 2000)
            } else {
                //Check for 200 resp from preSignedUrl
                const { message, presignedS3Url } = resp
                try {
                    checkUrl(presignedS3Url)
                    console.log('batch print flow getPdfUrl check for 200 on presignedUrl: ', resp)

                } catch (e: any) {
                    console.error('batch print flow getting presigned url response: ', e)
                }

                if (message && presignedS3Url) {
                    return { status, message, presignedS3Url }
                }
            }


        } catch (e: any) {
            console.error('Error getting pdf url ', e)
        }
    }

    //Delete tcn from tcns when deselecting from confirm screen. 
    const getPrintJobStatus = useCallback(async (numCalls = 0) => {
        console.log('batch print flow tcnResp.batchPrintJob', tcnResp.batchPrintJob)
        console.log('batch print flow tcnResp.batchPrintJob?.replace("Final.pdf", ""): ', tcnResp.batchPrintJob?.replace("/Final.pdf", ""))
        //console.log(`batch print flow numJobStatusCalls: ${numJobStatusCalls}`)
        console.log(`batch print flow getPrintJobStatus numCalls ${numCalls}`)
        let numJobStatusCalls = numCalls + 1
        try {
            let resp = await (fetch(`${process.env.NODE_ENV === "production" ? url : ""
                }/batchprint/job-status/${tcnResp.batchPrintJob?.replace("/Final.pdf", "")}`, {
                method: 'GET',
                credentials: 'include',
                signal: jobStatusController.signal
            }))

            console.log('batch print flow getPrintJobStatus resp: ', resp)

            if (resp.ok) {
                let maxCalls = tcns.length * 10 //Num of jobs selected * some number of max calls per item
                console.log('batch print flow num of transanactions: ', tcns.length)
                console.log('batch print flow maxCalls: ', maxCalls)
                const message = await resp.json()
                console.log('batch print flow getPrintJobStatus percent complete: ', parseInt(message.result.pct))
                if (parseInt(message.result.pct) === 100) {
                    console.log('batch print flow getPrintJobStatus message.result: ', message.result)
                    setTimeout(() => {
                        getPdfUrl()
                    }, 2000)
                } else if (parseInt(message.result.pct) < 100) {
                    setPercentComplete(parseInt(message.result.pct))
                    console.log(`batch print flow getPrintJobStatus percent complete ${parseInt(message.result.pct)} calling getPrintJobStatus`)
                    setTimeout(() => {
                        console.log('batch print flow getPrintJobStatus called')
                        getPrintJobStatus(numJobStatusCalls)
                    }, 3000)
                } else {
                    console.error('batch print flow Error retrieving job status')
                    jobStatusController.abort()
                    checkPdfController.abort()
                    setBatchPrintSteps({
                        stepA_Confirm: false,
                        stepB_PreparePdf: false,
                        stepC_DocumentReady: false,
                        stepD_Confirm: false,
                        cancelConfirmPrint: false,
                        cancelConfirmError: false,
                        docExpired: false,
                        pdfGenError: true,
                        confirmingPrintJobs: false
                    })
                    return
                }
            } else {
                console.error('batch print flow Error retrieving job status')
                jobStatusController.abort()
                checkPdfController.abort()
                setBatchPrintSteps({
                    stepA_Confirm: false,
                    stepB_PreparePdf: false,
                    stepC_DocumentReady: false,
                    stepD_Confirm: false,
                    cancelConfirmPrint: false,
                    cancelConfirmError: false,
                    docExpired: false,
                    pdfGenError: true,
                    confirmingPrintJobs: false
                })

                return
            }

        } catch (e: any) {
            console.error('Error submmiting tcns: ', e)
            console.log('job-status catch block getPrintJobStatus called')
        }

    }, [tcnResp.batchPrintJob])

    useEffect(() => {

        if (batchPrintSteps.stepB_PreparePdf && tcnResp.batchPrintJob && numJobStatusCalls <= 2) {
            console.log('batch print flow useEffect tcnResp.batchPrintJob: ', tcnResp.batchPrintJob)
            setTimeout(() => {
                getPrintJobStatus()
            }, 5000)
        }

    }, [batchPrintSteps.stepB_PreparePdf, tcnResp.batchPrintJob])


    const checkUrl = async (presignedUrl: string) => {
        console.log('batch print flow checking url: ')
        let resp = await fetch(presignedUrl, {
            signal: checkPdfController.signal
        })

        if (resp.status === 200) {
            setPercentComplete(100)
            setTimeout(() => {
                setPdfAvailable(true)
                setPreSignedUrl(presignedUrl)
            }, 1500)
            return
        } else {
            checkUrl(presignedUrl)
        }
    }

    useEffect(() => {
        console.log(`batch print flow pdfAvailable ${pdfAvailable} tcns:`, tcns)
        if (pdfAvailable) {
            setBatchPrintSteps((prevSteps: any) => {
                return {
                    ...prevSteps, stepB_PreparePdf: false, stepC_DocumentReady: true, pdfGenError: false,
                    confirmingPrintJobs: false
                }
            })
            window.open(presignedS3Url, '_blank')
        }
    }, [pdfAvailable, rows, tcns])

    const openPrintConfirm = () => {
        setBatchPrintSteps({
            stepA_Confirm: false,
            stepB_PreparePdf: false,
            stepC_DocumentReady: false,
            stepD_Confirm: true,
            cancelConfirmPrint: false,
            cancelConfirmError: false,
            docExpired: false,
            pdfGenError: false,
            confirmingPrintJobs: false
        })

    }

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('batch printing select all')
        if (event.target.checked && rows) {
            const newSelected = tcns.map((row: any) => row.transactionNumber);
            console.log('batch print flow set selected: ', newSelected)
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    }

    const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        console.log('batch print flow set selected: ', newSelected)
        setSelected(newSelected);

    };

    const isSelected = (tcn: string) => {
        console.log(`batch print confirm isSelected selected: ${selected} tcn: `, tcn)
        return selected.indexOf(tcn) !== -1;  //this checks tcn which it only gets from select all
    }

    useEffect(() => {
        console.log('batch print confirm selected: ', selected)
    }, [selected.length])

    const formatCountdownFriendly = (remainInMs: number) => {
        const remainInSecond = Math.round(remainInMs / 1000);
        const mins = Math.floor(remainInSecond / 60);
        const secs = remainInSecond % 60;
        const formatTime = `${String(mins)}:${String(secs).padStart(2, '0')} ${mins === 0 ? 'seconds' : 'minutes'}`;
        return formatTime;
    };

    useEffect(() => {
        if (batchPrintSteps.stepC_DocumentReady) setActiveTimer(true)
    }, [batchPrintSteps.stepC_DocumentReady])

    useEffect(() => {
        if (activeTimer) {
            const interval = setInterval(() => {
                console.log('batch print flow remaining: ', remaining)
                if (remaining > 0) {
                    setRemaining((prevTime) => prevTime - 1000)
                } else {
                    setBatchPrintSteps({
                        stepA_Confirm: false,
                        stepB_PreparePdf: false,
                        stepC_DocumentReady: false,
                        stepD_Confirm: false,
                        cancelConfirmPrint: false,
                        cancelConfirmError: false,
                        docExpired: true,
                        pdfGenError: false,
                        confirmingPrintJobs: false
                    })
                    clearInterval(interval)
                }
            }, 1000);

            if (batchPrintSteps.stepD_Confirm) {
                clearInterval(interval)
            }

            return () => {
                clearInterval(interval);
            };

        }


    }, [activeTimer, remaining, batchPrintSteps.stepD_Confirm]);

    console.log('batch print flow batchPrintSteps: ', batchPrintSteps);
    console.log(`batch print flow select all enabled tcns.length ${tcns.length} > 0 && selected ${selected.length} === ${tcns.length}: `, tcns.length > 0 && selected.length === tcns.length)

    const runPostConfirmActions = useCallback(() => {
        console.log('debug runPostConfirmActions: ', {
            setSearchParams,
            setHeaderButtonConfig,
            setSelected,
        })

        setBatchPrintSteps({
            stepA_Confirm: false,
            stepB_PreparePdf: false,
            stepC_DocumentReady: false,
            stepD_Confirm: false,
            cancelConfirmPrint: false,
            cancelConfirmError: false,
            docExpired: false,
            pdfGenError: false,
            confirmingPrintJobs: false
        })
        handleClose()
        setSearchParams([])
        setHeaderButtonConfig({ ...headerButtonConfig, disabled: true })
        setShowSubmissionSuccessful(false)
        setResp({})
        setSelected([])
        setTcns([])
        tcns.map((tcn: any) => {
            console.log('Debug tcns key: ', tcn.key);
            addAuditEvent("Printed Successful", "Batchprint / Selected Applicant", new Date(), tcn.key)

        })
    }, [setSearchParams, setHeaderButtonConfig, setSelected])

    return (
        <>
            <Dialog onClose={handleClose} open={open} fullWidth={true}
                PaperProps={{
                    style: {
                        minWidth: batchPrintSteps.stepD_Confirm ? "80vw" : "600px",
                        minHeight: batchPrintSteps.stepC_DocumentReady ? "329px" :
                            batchPrintSteps.stepD_Confirm ? "831px" : "221px"
                    }
                }}>
                <Paper sx={{
                    overflowY: 'hidden',
                    minWidth: batchPrintSteps.stepD_Confirm ? "80vw" : "600px",
                    minHeight: batchPrintSteps.stepC_DocumentReady ? "329px" :
                        batchPrintSteps.stepD_Confirm ? "831px" : "221px",
                    backgroundColor: 'background.default'
                }}
                >
                    <DialogTitle>
                        {batchPrintSteps.stepA_Confirm
                            ? "STEP 1: CONFIRM SELECTION"
                            : batchPrintSteps.stepB_PreparePdf
                                ? "STEP 2: PREPARING PDF DOCUMENT..."
                                : batchPrintSteps.stepC_DocumentReady
                                    ? "STEP 2: PDF DOCUMENT READY"
                                    : batchPrintSteps.stepD_Confirm
                                        ? "STEP 3: CONFIRM DOCUMENTS HAVE PRINTED"
                                        : batchPrintSteps.pdfGenError
                                            ? "Compilation Error"
                                            : batchPrintSteps.docExpired
                                                ? "PDF DOCUMENT EXPIRED"
                                                : batchPrintSteps.cancelConfirmPrint || batchPrintSteps.cancelConfirmError
                                                    ? "ARE YOU SURE YOU WANT TO CANCEL?"
                                                    : batchPrintSteps.confirmingPrintJobs
                                                        ? "CONFIRMING BATCH PRINT JOB"
                                                        : ""
                        }
                    </DialogTitle>
                    <DialogContent sx={{
                        ...BatchPrintingStyles.printModalContent,
                        justifyContent: batchPrintSteps.stepD_Confirm ? "flex-start" : "space-evenly",
                        width: batchPrintSteps.stepD_Confirm ? "100%" : "600px",
                        minHeight: batchPrintSteps.stepC_DocumentReady ? "214px" :
                            batchPrintSteps.stepD_Confirm ? "684px" : "92px",
                    }}
                    >
                        {
                            batchPrintSteps.stepB_PreparePdf && (
                                <Container disableGutters sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-evenly" }}>
                                    <LinearProgress
                                        variant={percentComplete === 0 ? "indeterminate" : "determinate"}
                                        value={percentComplete}
                                        sx={{ width: "95%", marginRight: "8px" }}
                                    />
                                    <Typography>{percentComplete}%</Typography>
                                </Container>
                            )
                        }
                        {
                            batchPrintSteps.stepA_Confirm && (
                                <Typography variant="body1">
                                    You have selected <span style={{ width: "max-content", fontWeight: 500 }}>({tcns.length}) {tcns.length > 1 ? "transactions" : "transaction"} </span>to print. Select 'Continue' to compile all transactions into a PDF dcoument that will be printed from a new tab.
                                </Typography>
                            )
                        }
                        {
                            batchPrintSteps.stepB_PreparePdf && (
                                <Typography variant="body1">
                                    Please wait while your PDF  doument is prepared. When the document is ready, it will automatically open in a new tab
                                </Typography>
                            )
                        }
                        {
                            batchPrintSteps.stepC_DocumentReady && (
                                <>
                                    <LinearProgress variant="determinate" value={100} sx={{ width: "100%" }} />
                                    <Typography variant="body1">
                                        Please navigate to the tab containing your PDF document. When you have finished printing, return to this screen and select 'continue'.
                                        If an error occurs while printing, or you wish to return all transactions to the batch pool, select the 'cancel' button.

                                        The PDF document will expire in <b>{formatCountdownFriendly(remaining)}</b>.

                                    </Typography>
                                    <Link target="_blank" href={presignedS3Url} rel="noopener">PDF document</Link>

                                </>

                            )
                        }
                        {
                            batchPrintSteps.stepD_Confirm && (
                                <Typography sx={{ minWidth: "100%" }} variant="body1">
                                    Please check and confirm all printing was successful. Uncheck any printing jobs that were not successful. Unchecked documents will be returned to the batch print pool.
                                    Confirmed print jobs will be removed from the batch pool. For a history of completed print jobs or to reprint already printed job, visit applicant profile history.
                                </Typography>
                            )
                        }
                        {
                            (batchPrintSteps.cancelConfirmPrint || batchPrintSteps.cancelConfirmError) && (
                                <Typography sx={{ maxWidth: "600px" }} variant="body1">
                                    Cancelling this transaction will return all selected transaction(s) back to the batch pool.
                                </Typography>
                            )
                        }

                        {
                            batchPrintSteps.stepD_Confirm && (

                                <TableContainer sx={BatchPrintingStyles.printModalTableContainer} component={Paper}>
                                    <Table sx={BatchPrintingStyles.printModalTable} stickyHeader aria-label={label}>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    PrintModalHeaderCells.map((cell: any, index: number) => {
                                                        return (
                                                            <TableCell
                                                                sx={BatchPrintingStyles.tableHeadCellStyle}
                                                                key={cell.label}
                                                                align={'left'}
                                                                padding={cell.disablePadding ? 'none' : 'normal'}
                                                                sortDirection={orderBy === cell.dataIndex ? sortOrder : false}>

                                                                <TableSortLabel
                                                                    hideSortIcon={cell.dataIndex === hoverLabel ? false : true}
                                                                    onMouseEnter={() => setHoverLabel(cell.dataIndex)}
                                                                    onMouseLeave={() => setHoverLabel("")}
                                                                    active={orderBy === cell.dataIndex}
                                                                    direction={orderBy === cell.dataIndex ? sortOrder : 'asc'}
                                                                    onClick={createSortHandler(cell.dataIndex)}
                                                                    IconComponent={(iconE: any) => (
                                                                        (label === 'agency-management' || label == "rapback-summary" || label === "print-confirmation" || label === "batch-printing" || label === "transaction-summary")
                                                                            && cell.dataIndex !== 'actions' && cell.dataIndex !== 'checkbox'
                                                                            ? (sortOrder === 'asc' && orderBy === cell.dataIndex)
                                                                                ? <ArrowUpwardIcon sx={{ marginRight: 2, height: "20px", width: "20px", color: 'text.secondary' }} />
                                                                                : <ArrowDownwardIcon sx={{ marginRight: 2, height: "20px", width: "20px", color: 'text.secondary' }} />
                                                                            : null
                                                                    )}
                                                                    sx={BatchPrintingStyles.printModaltableSortLabel}
                                                                    color='text.primary'>
                                                                    {
                                                                        // select all button 
                                                                        cell.label === "Full Name"
                                                                            ? <Checkbox
                                                                                checked={tcns.length > 0 && selected.length === tcns.length}
                                                                                onChange={handleSelectAll} aria-label="select all" />
                                                                            : null
                                                                    }

                                                                    <Typography sx={BatchPrintingStyles.printModalCellLabel}>
                                                                        {cell.label}
                                                                    </Typography>
                                                                </TableSortLabel>
                                                            </TableCell>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody sx={BatchPrintingStyles.printModalTableBody} >
                                            {
                                                visibleRows.map((row: any) => {
                                                    const isItemSelected = isSelected(row.transactionNumber)
                                                    return (
                                                        <TableRow selected={isItemSelected} aria-checked={isItemSelected} onClick={(e) => {
                                                            handleClick(e, row.transactionNumber)
                                                        }} key={row.key}>
                                                            {
                                                                PrintModalHeaderCells.map((cell: any, index: number) => {
                                                                    return (
                                                                        <TableCell key={cell.dataIndex + index}
                                                                            sx={{
                                                                                ...BatchPrintingStyles.printmodalTableCell,
                                                                                display: cell.dataIndex === "fullName" ? "flex" : "table-cell",
                                                                            }}>
                                                                            {
                                                                                cell.dataIndex === "fullName" && (
                                                                                    <Checkbox
                                                                                        color="primary"
                                                                                        checked={isItemSelected}   //Select all works need to fix individual select somehow
                                                                                        inputProps={{
                                                                                            'aria-labelledby': `select-${cell.dataIndex}`
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            }

                                                                            <Typography variant='body2'>{cell.dataIndex === "fullName" ? row.firstName + " " + row.lastName : row[cell.dataIndex]}</Typography>
                                                                        </TableCell>
                                                                    )
                                                                })
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        }
                        {
                            batchPrintSteps.confirmingPrintJobs && (
                                <Container disableGutters sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-evenly" }}>
                                    <LinearProgress
                                        variant="indeterminate"
                                        sx={{ width: "95%", marginRight: "8px" }}
                                    />
                                </Container>
                            )
                        }
                        {
                            batchPrintSteps.pdfGenError && (
                                <Typography sx={{ maxWidth: "600px" }} variant="body1">
                                    An error has occurred while preparing a PDF document containing the selected transaction(s).
                                    Please 'try again' or 'cancel'.
                                </Typography>
                            )
                        }
                        {
                            batchPrintSteps.docExpired && (
                                <Typography sx={{ maxWidth: "600px" }} variant="body1">
                                    PDF Link has expired. If a new link is required select 'Try Again'. Otherwise select 'Cancel' to
                                    return to the Batch Print selection screen.
                                </Typography>
                            )
                        }
                        {

                        }

                    </DialogContent>
                    <DialogActions sx={{ pr: '24px', gap: '8px' }}>
                        {
                            batchPrintSteps.cancelConfirmPrint
                                ? <Button onClick={() => handleContinuePrintConfirm()} variant="outlined" sx={{ color: mode === 'dark' ? 'text.primary' : '' }}>
                                    No, Continue
                                </Button>
                                : batchPrintSteps.cancelConfirmError
                                    ? <Button onClick={() => handleContinueErrorConfirm()} variant="outlined" sx={{ color: mode === 'dark' ? 'text.primary' : '' }}>
                                        No, Continue
                                    </Button>
                                    : batchPrintSteps.stepA_Confirm
                                        ? <Button onClick={() => handleCancelStepA()} variant="outlined" sx={{ color: mode === 'dark' ? 'text.primary' : '' }}>
                                            CANCEL
                                        </Button>
                                        : <Button onClick={() => handleCancel()} variant="outlined" sx={{ color: mode === 'dark' ? 'text.primary' : '' }}>
                                            CANCEL
                                        </Button>
                        }
                        {
                            batchPrintSteps.stepA_Confirm && (
                                <Button disabled={submissionSuccessful ? true : false} onClick={handleSubmitTcns} variant="contained" color='secondary'>
                                    Continue
                                </Button>
                            )
                        }
                        {
                            batchPrintSteps.stepB_PreparePdf && (
                                <Button disabled={true} variant="contained" color='secondary'>Continue</Button>
                            )
                        }
                        {
                            batchPrintSteps.stepC_DocumentReady && (
                                <Button onClick={openPrintConfirm} variant="contained" color='secondary'>Continue</Button>

                            )
                        }
                        {
                            batchPrintSteps.stepD_Confirm && (
                                <Button onClick={async () => {
                                    setBatchPrintSteps({
                                        stepA_Confirm: false,
                                        stepB_PreparePdf: false,
                                        stepC_DocumentReady: false,
                                        stepD_Confirm: false,
                                        cancelConfirmPrint: false,
                                        cancelConfirmError: false,
                                        docExpired: false,
                                        pdfGenError: false,
                                        confirmingPrintJobs: true
                                    })

                                    handlePrintConfirm(selected, searchParams).then(() => {
                                        runPostConfirmActions()
                                    })
                                }}
                                    variant="contained"
                                    disabled={selected.length === 0 ? true : false}
                                    color='secondary'>
                                    Confirm
                                </Button>
                            )
                        }
                        {
                            (batchPrintSteps.cancelConfirmPrint || batchPrintSteps.cancelConfirmError) && (
                                <Button onClick={handleClose} variant="contained" color='secondary'>
                                    Yes, Cancel
                                </Button>
                            )
                        }
                        {
                            (batchPrintSteps.pdfGenError || batchPrintSteps.docExpired) && (
                                <Button onClick={() => {
                                    setBatchPrintSteps({
                                        stepA_Confirm: true,
                                        stepB_PreparePdf: false,
                                        stepC_DocumentReady: false,
                                        stepD_Confirm: false,
                                        cancelConfirmPrint: false,
                                        cancelConfirmError: false,
                                        docExpired: false,
                                        pdfGenError: false,
                                        confirmingPrintJobs: false
                                    }); handleOpenPrint()
                                }} variant="contained" color='secondary'>Try Again</Button>

                            )
                        }
                    </DialogActions>
                </Paper>
            </Dialog>
        </>
    )
}