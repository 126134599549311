import { urls } from "../urls";
import { useEffect, useRef, useState } from "react";
import {
    generateRBScubscriptionQueryParams, getCurrentEnv,
    LogConfig,
    logger,
    transformMaintenanceData, transformPrenotificationsData,
    transformRapBackData, transformValidationsData
} from "../utils";
import { QueryRequestBody } from "interfaces/common";
import { PageNames } from "interfaces/config";
import { useTableStore } from "../state";
import { AlertColor } from "customEnums/AlertColor";
import { useRapbackMaintenanceStore } from '../pages/RapbackSubscriptions/RapbackMaintenance/state'
import { TableNames } from "customEnums/TableNames";
import { Auth } from "aws-amplify";
import { ConsoleExperience } from "amazon-quicksight-embedding-sdk";
import { useAuth } from "./useAuth";
import { handleUnauthorized } from "utils/handleUnauthorized";

export const getCount = (obj: object) => {
    return Object.values(obj).filter(value => value === true).length
}

interface RunQueryConfig {
    refs: {
        runCount: number,
        orderByRef: string,
        orderRef: string,
        pageSizeRef: number,
        pageRef: number
    },
    navLinksExtraUpdate: boolean,
    runCountOverTwo: boolean,
    initialInvocation: boolean,
    caughtOldSearch: boolean,
    pageChanged: boolean,
    pageSize: number,
    currentPage: number,
    order: string,
    orderBy: string,
    tableName: string,
    userRole: string,
    filterLabels: any[],
    searchParams: any[],
    pageName: string,
    searchingNonRbPage: boolean
}

export const useRapbackSubscription = (
    pageName: string,
    pageSize: number,
    currentPage: number,
    order: string,
    orderBy: string,
    userRole: any,
    searchParams: any,
    auditSearch: boolean,
    tableName: string = "",
    queryParams: { value: string, name: string }[] = [],
    initialInvocation: boolean = false
) => {

    const STATE = getCurrentEnv
    const queryUrl = urls.QUERY
    const rapbackUrl = urls.RAPBACK

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const [mappedData, setMappedData] = useState<any>()
    const [totalRecords, setTotalRecords] = useState(0);
    const setAlertTitle = useTableStore(state => state.setAlertTitle);
    const setAlertMessage = useTableStore(state => state.setAlertMessage);
    const setSeverity = useTableStore((state) => state.setSeverity)
    const setOpenNotification = useTableStore((state) => state.setOpenNotification)
    const setOutStandingRapback = useTableStore((state) => state.setOutStandingRapback)
    const outstandingRapback = useTableStore((state) => state.outstandingRapback)
    const setNeedsAttention = useTableStore(state => state.setNeedsAttention);
    const fullName = useRapbackMaintenanceStore(state => state.fullName)
    const receivedResponse = useTableStore((state) => state.receivedResponse)
    const setReceivedResponse = useTableStore((state) => state.setReceivedResponse)
    const columnState = useTableStore(state => state.columnState)
    const tableConfig = useTableStore(state => state.tableConfig)
    const runFilters = useTableStore(state => state.runFilters)
    const setRunFilters = useTableStore(state => state.setRunFilters)
    const runCount = useRef(0);
    const pageRef = useRef(currentPage)
    const pageSizeRef = useRef(pageSize)
    const orderByRef = useRef(orderBy)
    const orderRef = useRef(order)
    const [refetchParams, setRefetchParams] = useState<any>({})
    const { logout } = useAuth();


    console.log('run useRBSub orderBy in query ', { orderBy, pageName })

    console.log(`debug updated config useRapbackSubscription: `, { columnState, tableConfig })

    const statusFilters = columnState.page["rapback-maintenance"]!.filters?.find(filter => filter.filterLabel === "Filter Status")
    const timeFilters = columnState.page["rapback-validations"]!.filters?.find(filter => filter.filterLabel === "Time Remaining")

    const fetchMaintenance = async (queryDataRequest: any, queryCountRequest: any, initialInvocation?: boolean) => {

        const url: string = `${process.env.NODE_ENV === "production" ? queryUrl : ""}/query`;
        try {
            setLoading(true)

            const data = await (await fetch(url, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(queryDataRequest)
            })).json();

            handleUnauthorized(data.message, logout);

            let updatedOutstandingRapback = { ...outstandingRapback }
            if (initialInvocation && data['queriesResults'].length > 1) {
                if (data['queriesResults'][0].results.length > 0) {
                    updatedOutstandingRapback = { ...updatedOutstandingRapback, preNotification: true };
                    setNeedsAttention(true)
                }
                if (data['queriesResults'][1].results.length > 0) {
                    updatedOutstandingRapback = { ...updatedOutstandingRapback, validations: true };
                    setNeedsAttention(true)
                }
                console.log(`debug rb sub setting outstanding rapback to ${updatedOutstandingRapback} params: `, {
                    initialInvocation,
                    dataQueriesResultsLength: data['queriesResults'].length
                })
                setOutStandingRapback(updatedOutstandingRapback);
            } else {
                const { results } = data['queriesResults'][0];
                results.forEach((result: any) => {
                    if (result.isPending === "false") {
                        if (result.needsConfirm === "true") {
                            updatedOutstandingRapback = { ...updatedOutstandingRapback, preNotification: true };
                            setNeedsAttention(true)
                        }
                        if (result.daysToExpire <= 60 && result.daysToExpire !== null && result.isActive === "true") {
                            updatedOutstandingRapback = { ...updatedOutstandingRapback, validations: true };
                            setNeedsAttention(true)
                        }
                    }
                });
                
                const countData = await (await fetch(url, {
                    method: "POST",
                    credentials: "include",
                    body: JSON.stringify(queryCountRequest)
                })).json();

                if (!countData['queriesResults'][0].errorMessage) {
                    setTotalRecords(countData['queriesResults'][0].results[0].count)
                }

                const transformedData = results.map((obj: any, index: number) => {

                    switch (pageName) {
                        case PageNames.RAPBACK_MAINTENANCE:
                            return transformMaintenanceData(obj, index)
                        case PageNames.RAPBACK_PRENOTIFICATIONS:
                            return transformPrenotificationsData(obj, index)
                        case PageNames.RAPBACK_VALIDATIONS:
                            let transformed = transformValidationsData(obj, index)
                            return transformed
                    }
                });

                setMappedData(transformedData);
                setLoading(false);
            }

        } catch (err: any) {
            console.error('error querying rapback subs: ', err)
            setError(err);
            setLoading(false);
        }
    }

    const handleConfirmPrenotification = async (subscriptionId: string) => {
        console.log(`handleConfirmPrenotification ran`)
        const { username } = await Auth.currentAuthenticatedUser();
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/confirm/${subscriptionId}?username=${username}`;

        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            if (resp.ok) {
                fetchMaintenance(refetchParams.queryDataRequest, refetchParams.queryCountRequest, refetchParams.initialInvocation)

                setAlertTitle("Request Submitted");
                setAlertMessage(`A pre-notification acceptance request has been submitted for ${fullName}. View pending details on the Rapback Maintenance screen.`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Failed`)
                setAlertMessage(`Please try again to accept ${fullName}'s subscription`)
                setReceivedResponse(true)
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleDenyPrenotification = async (subscriptionId: string) => {
        const { username } = await Auth.currentAuthenticatedUser();
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/deny/${subscriptionId}?deletion_source=prenotify&username=${username}`;

        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            if (resp.ok) {
                fetchMaintenance(refetchParams.queryDataRequest, refetchParams.queryCountRequest, refetchParams.initialInvocation)
                setAlertTitle("Request Submitted");
                setAlertMessage(`A pre-notification rejection request has been submitted for ${fullName}. View pending detials on the Rapback Maintenance Screen.`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
                fetchMaintenance(refetchParams.queryDataRequest, refetchParams.queryCountRequest, refetchParams.initialInvocation)

            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Failed`)
                setAlertMessage(`Please try again to decline ${fullName}'s subscription`)
                setReceivedResponse(true)
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleUpdateSubscription = async (subscriptionId: string, expirationDate?: string, dob?: string) => {
        const { username } = await Auth.currentAuthenticatedUser();
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/update/${subscriptionId}?username=${username}`;

        //Old format for name reference
        // const body = {
        //     expiration_date: expirationDate,
        //     dob,
        //     rapback_id: subscriptionId
        // }

        const body = { rapback_id: subscriptionId }

        if (expirationDate) body['expiration_date'] = expirationDate
        if (dob) body['dob'] = dob

        console.log('handleUpdateSub body: ', body)
        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(body)
            })
            if (resp.ok) {
                fetchMaintenance(refetchParams.queryDataRequest, refetchParams.queryCountRequest, refetchParams.initialInvocation)

                setAlertTitle("Request Successfully Submitted");
                setAlertMessage(`Edit Requests will be reviewed`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
                fetchMaintenance(refetchParams.queryDataRequest, refetchParams.queryCountRequest, refetchParams.initialInvocation)

            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Error`)
                setAlertMessage(`Your attempt to edit has failed, please try again or contact support`)
                setReceivedResponse(true)
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleUnsubscribe = async (subscriptionId: string) => {
        const { username } = await Auth.currentAuthenticatedUser();
        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/unsubscribe/${subscriptionId}?deletion_source=${pageName === "rapback-maintenance" ? "maintenance" : "validation"}&username=${username}`;

        try {
            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            console.log('unsubscribe response: ', resp)
            if (resp.ok) {
                if (pageName === "rapback-maintenance") {
    fetchMaintenance(refetchParams.queryDataRequest, refetchParams.queryCountRequest, refetchParams.initialInvocation)
                    setOpenNotification(true);
                    setAlertTitle("Request Successfully Submitted");
                    setAlertMessage(`Unsubscribe request will be reviewed. If approved, ${fullName} will be unsubscribed from receiving Rapbacks.`)
                    setSeverity(AlertColor.SUCCESS)
                    setReceivedResponse(true)
                }
                if (pageName === "rapback-validations") {
                    setAlertTitle("Request Submitted");
                    setAlertMessage(`A rapback subscription cancellation request has been submitted for ${fullName}. View pending detials on the Rapback Maintenance Screen.`)
                    setSeverity(AlertColor.SUCCESS)
                    setOpenNotification(true)
                    setReceivedResponse(true)

                }
                console.log('debug refetch unsubscribe: ', refetchParams)
                fetchMaintenance(refetchParams.queryDataRequest, refetchParams.queryCountRequest, refetchParams.initialInvocation)
                return resp.ok
            } else {
                if (pageName === "rapback-maintenance") {
                    setOpenNotification(true);
                    setSeverity(AlertColor.ERROR)
                    setAlertTitle(`Request Error`)
                    setAlertMessage(`Your attempt to unsubscribe ${fullName} has failed, please try again or contact support.`)
                    setReceivedResponse(true)
                }
                if (pageName === "rapback-validations") {
                    setOpenNotification(true);
                    setSeverity(AlertColor.ERROR)
                    setAlertTitle(`Request Failed`)
                    setAlertMessage(`Please try again to cancel ${fullName}'s subscription`)
                    setReceivedResponse(true)
                }
                return false
            }
        } catch (err: any) {
            setError(err)
        }
    }

    const handleRenew = async (subscriptionId: string) => {
        console.log(`Validations handleRenew hook subscriptionId: ${subscriptionId}`)
        const { username } = await Auth.currentAuthenticatedUser();

        const url: string = `${process.env.NODE_ENV === "production" ? rapbackUrl : ""}/subscription/renew/${subscriptionId}?username=${username}`;

        console.log(`Validations handleRenew hook url: ${url}`)

        try {
            console.log(`Validations handleRenew hook fetching....`)

            const resp = await fetch(url, {
                method: "POST",
                credentials: "include"
            })

            if (resp.ok) {
                fetchMaintenance(refetchParams.queryDataRequest, refetchParams.queryCountRequest, refetchParams.initialInvocation)
                setAlertTitle("Request Submitted");
                setAlertMessage(`A rapback subscription renewal request has been submitted for ${fullName}. View pending details on the Rapback Maintenance screen.`)
                setSeverity(AlertColor.SUCCESS)
                setOpenNotification(true)
                setReceivedResponse(true)
            } else {
                setOpenNotification(true);
                setSeverity(AlertColor.ERROR)
                setAlertTitle(`Request Failed`)
                setAlertMessage(`Please try again to renew ${fullName}'s subscription`)
            }
            return resp.ok
        } catch (err: any) {
            setError(err)
        }
    }

    const getRunQuery = (runQueryConfig: RunQueryConfig) => {
        const { searchingNonRbPage, runCountOverTwo, navLinksExtraUpdate, pageChanged, caughtOldSearch, tableName, userRole, filterLabels, searchParams } = runQueryConfig
        let pageIsMaintenance = pageName === PageNames.RAPBACK_MAINTENANCE
        let runPrenotificationQuery = userRole.length > 1
        let runMaintenanceValidationsQuery = userRole && filterLabels.length > 0
        let awaitingExpectedFilters = tableConfig[tableName]?.filter && filterLabels.length < 1
       console.log('debug rb sub condition getRunQuery: ', {
        awaitingExpectedFilters, 
        tableName, 
        tableConfig: tableConfig[tableName],
        initialInvocation
       })
       if(tableConfig) {
        if((awaitingExpectedFilters === undefined || awaitingExpectedFilters === true ) && !initialInvocation) {
            console.log('debug rb sub condition false')
            return false
            
        } else {
            if (pageChanged) {
                console.log('debug rb sub condition true')
                return true
            }
            
            if (!pageChanged && (searchingNonRbPage || caughtOldSearch || navLinksExtraUpdate)  ) {
                console.log('debug rb sub condition false')
                return false
            } 
    
            if (initialInvocation && !searchingNonRbPage) {
                console.log('debug rb sub condition ', runPrenotificationQuery)
                return runPrenotificationQuery
            }  
          
            if (!initialInvocation || searchParams.length >= 0) {
                console.log('debug running rbsub query F')
                console.log(`debug rb sub pageName ${pageName} === ${PageNames.RAPBACK_PRENOTIFICATIONS}: `, pageName === PageNames.RAPBACK_PRENOTIFICATIONS)
                if(pageName === PageNames.RAPBACK_PRENOTIFICATIONS) {
                    console.log('debug rb sub running prenotification query: ', runPrenotificationQuery); 
                    console.log('debug running rbsub query G')
                    return runPrenotificationQuery
                } else {
                    console.log('debug rb sub running maintenance / validation query: ', runMaintenanceValidationsQuery)
                    console.log('debug running rbsub query H')
                    return runMaintenanceValidationsQuery
                }
                    
            }
        }
       }
        
        
    }

    useEffect(() => {
        runCount.current = runCount.current + 1;
        console.log('debug filter runCount: ', { searchParams, runCount: runCount.current, filters: columnState.page[pageName][tableName]?.filters, runFilters })

        console.log('recevied response or no', receivedResponse)
        const controller = new AbortController();
        //update column names for dates
        const dateIndexs = [
            "expirationDate",
            "lowerDate",
            "upperDate",
            "dob" ,
            "subscriptionDate",
            "deletionDate",
            "receivedDate"
        ]
        const param = searchParams.find((param) => dateIndexs.includes(param.column) )

        let filters = columnState.page[pageName][tableName]?.filters
        const filterLabels: any[] = []
        let filterNumbers: any[] = []
        if (filters) {
            filters.forEach(filter => {
                let filterValueKeys = Object.keys(filter.filterValues);
                filterValueKeys.forEach(key => {
                    const { boolNumber, selected, label } = filter.filterValues[key];
                    if (pageName === "rapback-validations") {
                        filterNumbers.push(boolNumber);
                    }
                    if (selected) {
                        console.log('filter.filterValues[key]: ', filter.filterValues[key]);
                        if (pageName === "rapback-maintenance") {
                            filterLabels.push(label);
                        } else if (pageName === "rapback-validations") {
                            filterLabels.push(boolNumber);
                        }
                    }
                });
            });
        }
        console.log('searchParams123123', searchParams, runCount.current);

        const runQueryConfig: RunQueryConfig = {
            refs: {
                runCount: runCount.current,
                pageRef: pageRef.current,
                pageSizeRef: pageSizeRef.current,
                orderByRef: orderByRef.current,
                orderRef: orderRef.current,
            },
            initialInvocation,
            caughtOldSearch: searchParams?.length > 0 && runCount.current <= 2,
            pageChanged: (pageRef.current != currentPage || (pageRef.current === 1 && currentPage === 1) || pageSizeRef.current != pageSize) || (orderByRef.current != orderBy || orderRef.current != order),
            navLinksExtraUpdate: runCount.current > 2 && !runFilters && searchParams?.length < 1 && pageRef.current === currentPage && pageSizeRef.current === pageSize && orderByRef.current === orderBy,
            runCountOverTwo: runCount.current > 2 && runFilters,
            searchingNonRbPage: searchParams?.length > 0 && pageName != PageNames.RAPBACK_MAINTENANCE && pageName != PageNames.RAPBACK_VALIDATIONS && pageName != PageNames.RAPBACK_PRENOTIFICATIONS,
            pageSize,
            currentPage,
            order,
            orderBy,
            tableName,
            userRole,
            filterLabels,
            searchParams,
            pageName
        }

        let runQuery = getRunQuery(runQueryConfig)
        console.log('12312312 run Query', runQuery, searchParams, runCount.current, runQueryConfig);
        console.log('debug rb sub stuck search debug rb sub double query: ', { runQuery, pageName, searchParams, orderBy, orderByRef: orderByRef.current, runCount: runCount.current, currentPage, pageCurrent: pageRef.current, runQueryConfig })

        if (runQuery) {
            let rbSubQueryParams
            if (queryParams.length > 0) {
                rbSubQueryParams = queryParams
            } else {
                rbSubQueryParams = generateRBScubscriptionQueryParams(pageName, searchParams, filterLabels, filterNumbers, orderBy, order, param)
            }

            console.log('rapbacksub query params: ', queryParams)
            console.log('debug rapback badge obj pagination: ', { pageSize, currentPage, offset: `(${currentPage} - 1) * ${pageSize}: ${(currentPage - 1) * pageSize}` })
            let queryDataRequest: QueryRequestBody = {
                "queries": initialInvocation ? [
                    {
                        "queryParams": [...generateRBScubscriptionQueryParams('rapback-prenotifications', searchParams, filterLabels, filterNumbers, 'subscriptionDate', order, param)],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackSubscriptionTable"
                    },
                    {
                        "queryParams": [...generateRBScubscriptionQueryParams('rapback-validations', searchParams, filterLabels, filterNumbers, 'daysToExpire', 'asc', param)],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackSubscriptionTable"
                    },
                ] : [
                    {
                        "queryParams": [...rbSubQueryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackSubscriptionTable"
                    }
                ]
            };

            const debugGetRunQuery: LogConfig = {
                identifier: "debug rb sub",
                pageName,
                thingToDebug: "runQuery",
                paramsToLog: {
                    conditionA: tableConfig[tableName]?.filter && filterLabels.length < 1,
                    conditionB: initialInvocation && !runQueryConfig.searchingNonRbPage,
                    conditionC: runQueryConfig.searchingNonRbPage,
                    conditionD: runQueryConfig.caughtOldSearch || runQueryConfig.navLinksExtraUpdate,
                    conditionE: runQueryConfig.pageChanged,
                    conditionF: !initialInvocation, 
                    isPrenotification: pageName === PageNames.RAPBACK_PRENOTIFICATIONS,
                    runPrenotificationQuery: userRole.length > 1,
                    runMaintenanceValidationsQuery: userRole && filterLabels.length > 0,
                    runQuery,
                    initialInvocation,
                    tableConfig
                }
            }
            // console.log utility 
            //logger(debugGetRunQuery)


            let queryCountRequest: QueryRequestBody = {
                "queries": [
                    {
                        "queryParams": [...rbSubQueryParams],
                        "limit": pageSize,
                        "offset": (currentPage - 1) * pageSize,
                        "queryName": "queryRapbackSubscriptionTableCount"
                    }
                ]
            }

            console.log('debug running rbsub query useEffect dependencies: ', {
                STATE,
                userRole,
                pageSize,
                currentPage,
                order,
                orderBy,
                searchParams,
                runFilters,
                columnState: columnState.page[pageName][tableName]?.filters?.length,
                statusFilters,
                timeFilters,
                tableName,
                initialInvocation


                //filtersLength: columnState.page[pageName][tableName]!.filters?.length,
            })

            if (STATE === "ga") {
                console.log('debug rb maintenance calling fetching maintence data ', STATE === 'ga')
                setRefetchParams({queryDataRequest, queryCountRequest, initialInvocation})
                fetchMaintenance(queryDataRequest, queryCountRequest, initialInvocation)
            }

        }
        setRunFilters(false)
        console.log('debug searchParams: ', searchParams)
    }, [STATE,
        userRole,
        pageSize,
        currentPage,
        order,
        orderBy,
        searchParams,
        searchParams?.length,
        runFilters,
        columnState.page[pageName][tableName]?.filters?.length,
        tableName,
        initialInvocation,
        tableConfig
    ]

    )

    return { data: mappedData, runCount: runCount.current, error, loading, totalRecords, fetchMaintenance, refetchParams, handleConfirmPrenotification, handleDenyPrenotification, handleUpdateSubscription, handleUnsubscribe, handleRenew };
}


