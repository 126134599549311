/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

import {
  Divider,
  Typography,
  Button,
  Alert,
  AlertTitle,
  MenuItem,
  TextField,
  CircularProgress,
  Grid,
  Container,
  Tab,
  Tabs,
  Box
} from "@mui/material";
import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import UpArrow from '@mui/icons-material/KeyboardArrowUp';
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  ApplicantDetailsActionTypes,
  useApplicantDetailsContext,
} from "../../ApplicantDetailsContext/ApplicantDetailsProvider";
import CustomEditor from "./CustomEditor/CustomEditor";
import useNotes from "./CustomEditor/useNotes";
import { useExpectedResponse } from "hooks/useExpectedResponses";
import { useAudit } from "hooks/useAudit";
import { v4 as uuid } from 'uuid'
import { ResultsProps } from "interfaces/pages/components";
import { useUserStore } from "../../../../../state";
import { extractPageName } from "common/CustomTable/helpers";
import { TableNames } from "customEnums/TableNames";
import { OutgoingTransmissionTable } from "./OutgoingTransmissionTable"
import { IncomingResponseTable } from "./IncomingResponseTable"
import { useMugshots } from "hooks/useMugshots";

const Results: React.FC<ResultsProps> = ({
  userRole,
  results,
  rapSheets,
  transactionalInfo,
  resultsResponses,
  tabValue,
  setEditRedactedMode,
  editRedactedMode,
  setTextChanges,
  textChanges,
  responseAssociationData
}) => {

  let originalText: string = "";
  if (rapSheets && rapSheets.length > 0) {
    rapSheets.forEach((str: string, index: number) =>
      index === 0
        ? (originalText = str)
        : (originalText =
          `
      ${originalText +
          `\n \n <strong>End of sheet ${index}</strong> \n ----------------------------------------------------------------------------------- \n `
          }` + `${str}`)
    );
  }

  const { notesJSON, fetchStatus } = useNotes("redact");
  const [isVisible, setIsVisible] = useState(true);
  const [rapSheetVisible, setRapSheetVisible] = useState(true);
  const [mugshotsVisible, setMugshotsVisible] = useState(true);
  const [view, setView] = useState('Original');
  const [redactedTextCreation, setRedactionTextCreation] = useState<string>('');
  const [createdRedaction, setCreatedRedaction] = useState<boolean>(false);
  const [tableData, setTableData] = useState<Array<Object>>(results);
  const [manualAdjudication, setManualAdjudication] = useState<boolean>(false);
  const [resultRespData, setResultRespData] = useState<Array<Object>>(resultsResponses)
  const locationObj = useLocation()
  const pageName = extractPageName(locationObj.pathname)
  const config = useUserStore(state => state.config)
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState<boolean>(false)
  const [moveTabs, setMoveTab] = useState(1);
  const { applicantImageUrls } = useMugshots(responseAssociationData);
  const userConfig = useUserStore(state => state.userConfig);
  const { usersPermissions } = userConfig;

  useEffect(() => {
    console.log('Add redaction has been activated:', editRedactedMode)
  }, [editRedactedMode])

  const decodeRedactedText = (text: any) => {
    let doc = new DOMParser().parseFromString(text, 'text/html');
    let decodedText: any = doc.documentElement.textContent;
    const sanitizedInput =
      decodedText
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&#x([0-9A-Fa-f]+);/g, (match: any, hex: any) => String.fromCharCode(parseInt(hex, 16)));
    return sanitizedInput;
  }

  const redactedText = decodeRedactedText(notesJSON?.[0]?.body)

  const changeView = (e: any) => {
    setView(e.target.value)
  }

  const toggleDataVisibility = () => {
    setRapSheetVisible(!rapSheetVisible);
    setMugshotsVisible(!mugshotsVisible);
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible)
  }

  useEffect(() => {
    if (resultsResponses.length !== 0) {
      console.log('CustomTableFooter onRowsPerPageChange resultResponses non sorted: ', resultsResponses)
      const sortResultRespData = resultsResponses.sort((a: any, b: any) => {
        if (a.type1Field7.toLowerCase() === 'state') return -1;
        if (b.type1Field7.toLowerCase() === 'state') return 1;
        return 0
      })
      console.log('CustomTableFooter onRowsPerPageChange resultResponses sorted: ', sortResultRespData)
      setResultRespData(sortResultRespData)
    }
  }, [resultsResponses])

  useEffect(() => {
    const sortTableData = results?.sort((a: any, b: any) => {
      if (a.agency.toLowerCase() === 'state') return -1;
      if (b.agency.toLowerCase() === 'state') return 1;
      return 0
    })
    setTableData(sortTableData);
    if (
      results?.some(
        (statuses: { status: string }) =>
          statuses.status === "Manual Adjudication"
      )
    )
      setManualAdjudication(true);
  }, [results, manualAdjudication, userRole]);

  type MyParams = {
    registrationId: string;
  };
  const { registrationId } = useParams<keyof MyParams>() as unknown as MyParams;
  const { applicantDetailsDispatch } = useApplicantDetailsContext();
  const { addAuditEvent } = useAudit();
  const { expectedResponse, receivedResponses } = useExpectedResponse(registrationId, resultRespData);
  const allResponsesReceived = expectedResponse === receivedResponses;
  const isLoading = fetchStatus === "loading" || fetchStatus === "idle";

  const notesLoadingContainer = {
    display: 'flex',
    padding: '1.5rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    alignSelf: 'stretch',
    backgroundColor: 'common.black'
  }

  if (isLoading) {
    return (
      <Grid sx={notesLoadingContainer}>
        <CircularProgress />
      </Grid>
    );
  }

  const updateDecision = async (e: any) => {
    console.log("update decision ran or e: ", e);
    applicantDetailsDispatch({
      type: ApplicantDetailsActionTypes.UPDATE_DECISION,
      payload: { decision: e },
    });
    await addAuditEvent(
      `${ApplicantDetailsActionTypes.UPDATE_DECISION}`,
      `Decision: ${e}`,
      new Date(),
      undefined
    );
  };

  const handleResponseType = (type: string) => {
    if (type.toLowerCase() === 'n') {
      return 'No Hit'
    }
    if (type.toLowerCase() === 'y') {
      return 'Hit'
    }
    if (type.toLowerCase() === 'errt') {
      return 'ERRT-Error'
    } else {
      return type
    }
  }

  let newResultRespData = resultRespData.map((v: any) => {
    console.log('CustomTableFooter onRowsPerPageChange resultResponses map v: ', v)
    return {
      ...v,
      uuid: uuid(),
      type2Field59: handleResponseType(v.type2Field59)
    }
  }
  )

  const canViewRedactedResult = usersPermissions?.["viewRedactedResults"];
  const canEditRedactedREsults = usersPermissions?.["editRedactedResults"];

  const resultsBodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '1rem',
    padding: '1.5rem',
    backgroundColor: 'common.black',
    height: 'auto'
  }
  const infoAlertStyle = {
    backgroundColor: 'info.main',
    border: '1px solid',
    borderColor: 'info.dark',
    width: '100%',
    '.MuiAlert-icon': {
      color: 'info.contrastText'
    }
  }
  const successAlertStyle = {
    width: '100%',
    border: '1px solid',
    borderColor: 'success.dark',
    backgroundColor: 'success.main',
    '.MuiAlert-icon': {
      color: 'success.contrastText'
    }
  }

  const renderTabs = (items: any[]) => {
    if (!config.pages[pageName]["responseData"]["displayMugshots"]) {
        return items.slice(0, -1).map((item, index) => <Tab key={index} label={item.label} />);
    }
    else {
      return items.map((item, index) => {
        let label;
        if (item.label === 'MUGSHOTS' && applicantImageUrls && applicantImageUrls.length) {
          label = `${item.label} (${applicantImageUrls.length})`
        } else {
          label = item.label;
        }
        return <Tab key={index} label={label}/>
      });
    }
  }

  const renderImages = (mugshotArray: { id: string; urls: string[]; }[]) => {
    if (mugshotArray && mugshotArray.length) {
      return mugshotArray.map((mugshot: {id: string, urls: string[]})=> {
        return <img style={{ height: "100%", width: "100%", margin: '0 8px' }}
          src={mugshot.urls[0]}
          alt='mugshot'
          loading="lazy"
        />
      })
    } else {
      return <div>NO DATA</div>
    }
  }

  let items = [
    {
      label: 'RESPONSE DATA',
      key: 'RESPONSE DATA',
      children: usersPermissions?.["viewOriginalResults"] &&
        <>
          { rapSheetVisible ? (
            <>
              {(usersPermissions?.["viewOriginalResults"] && view === 'Original') || (canViewRedactedResult && view === 'Redacted') ? (
                <CustomEditor
                  isEditable={false}
                  tcn={transactionalInfo.transactionNumber}
                  redactedJSON={notesJSON}
                  view={view}
                  setView={setView}
                  textChanges={textChanges}
                  registrationId={registrationId}
                  viewOriginal={originalText}
                  originalText={view === 'Original' ? originalText : redactedTextCreation ? redactedTextCreation : redactedText}
                  {...(view === 'Original' && {
                    title: "ORIGINAL",
                    setEditRedactedMode: setEditRedactedMode,
                    redactedTextCreation: redactedTextCreation,
                    createdRedaction: createdRedaction,
                    allResponsesRecieved: receivedResponses === expectedResponse,
                  })}
                  {...(view === 'Redacted' && {
                    title:"REDACTED",
                    redactedText: redactedText,
                    redactedTextCreation: redactedTextCreation,
                    createdRedaction: createdRedaction,
                  })}
                />
              ) : null}

              {canViewRedactedResult && usersPermissions?.["viewOriginalResults"] && view === 'Original & Redacted' ?
                <CustomEditor
                  isEditable={canEditRedactedREsults && editRedactedMode}
                  setEditRedactedMode={setEditRedactedMode}
                  editRedactedMode={editRedactedMode}
                  viewOriginal={originalText}
                  originalText={redactedTextCreation ? redactedTextCreation : redactedText !== 'undefined' ? redactedText : originalText}
                  redactedJSON={notesJSON}
                  tcn={transactionalInfo.transactionNumber}
                  view={view}
                  setView={setView}
                  setRedactionTextCreation={setRedactionTextCreation}
                  setCreatedRedaction={setCreatedRedaction}
                  createdRedaction={createdRedaction}
                  allResponsesRecieved={receivedResponses === expectedResponse}
                  setTextChanges={setTextChanges}
                  textChanges={textChanges}
                  tabValue={tabValue}
                  redactedText={redactedText}
                  registrationId={registrationId}
                />
                : null
              }
            </>)
          : null
          }
        </>
      },
    {
        label: 'MUGSHOTS',
        key: 'MUGSHOTS',
        children: usersPermissions?.["viewOriginalResults"] &&
        <>
          { mugshotsVisible ? (
            <>
              <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%', marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />
              <Grid sx={{ height: 'auto', width: '100%', p: 0 }} >
                <Grid display='flex' justifyContent='space-between' width='100%' alignItems='center'>
                  <Box display='flex' justifyContent='space-between' sx={{ minWidth: '175px', height: '220px' }}>
                    {renderImages(applicantImageUrls)}
                  </Box>
                </Grid>
              </Grid>
            </> ) 
          : null 
          }
        </>
    }
  ];

  const handleChange = (event?: any, newValue?: any, key?: any) => {
    if (editRedactedMode && textChanges) {
        setOpen(true)
        setMoveTab(newValue);
    } else {
        setEditRedactedMode(false);
        setValue(newValue);
    }
    applicantDetailsDispatch({ type: ApplicantDetailsActionTypes.UPDATE_TAB, payload: { currentTab: key } })
};

  return (
    <>
      <Container sx={resultsBodyStyle} disableGutters={true} maxWidth={false} >
        <Grid sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Typography variant='h6' color='text.primary'>RESULTS</Typography>
          <Button size='small' endIcon={isVisible ? <DownArrow style={{ color: '#8A72FF' }} /> : <UpArrow style={{ color: '#8A72FF' }} />}
            disableRipple onClick={toggleVisibility} sx={{ padding: '4px 5px' }}>
            {isVisible ? 'HIDE' : 'SHOW'}
          </Button>
        </Grid>
        {isVisible ? (
          <>
            <Typography variant='body1' color='text.primary' >
              Review all outgoing transmissions and incoming responses.
              {(usersPermissions?.["createNewRedactedResults"] || usersPermissions?.["editRedactedResults"]) &&
                ' You may redact response data when all AFIS responses are received.'
              }
            </Typography>
            <Typography variant='subtitle2' color='text.primary'>OUTGOING TRANSMISSION</Typography>
            <OutgoingTransmissionTable
              totalRecords={tableData.length}
              name={TableNames.OUTGOING_TRANSMISSION}
              tableData={tableData} />

            <Typography variant='subtitle2' color='text.primary' sx={{ marginTop: '1rem' }}>INCOMING RESPONSE</Typography>
            
            { expectedResponse &&
              <Alert
                severity={!allResponsesReceived ? "info" : 'success'}
                sx={allResponsesReceived ? successAlertStyle : infoAlertStyle}
              >
                <AlertTitle sx={{ color: allResponsesReceived ? 'success.contrastText' : 'info.contrastText' }}>
                  {allResponsesReceived ? 'All responses received.' : 'Responses pending.'}
                </AlertTitle>
                <Typography color={allResponsesReceived ? 'success.contrastText' : 'info.contrastText'} variant='body2'>
                  {receivedResponses ?? 0} of {expectedResponse ?? 0} responses received. {!allResponsesReceived && usersPermissions?.["createNewRedactedResults"] && 'Please wait on the remaining results to redact response information.'}
                </Typography>
              </Alert>
            }

            <IncomingResponseTable tableData={newResultRespData?.length ? newResultRespData : [{ type2Field59: "NO DATA" }]} totalRecords={newResultRespData.length} name={TableNames.INCOMING_RESPONSE} />


          </>
        ) : null }
        <Divider style={{ background: 'rgba(255, 255, 255, 0.12)', width: '100%', marginTop: '1rem', marginBottom: '1rem' }} variant="fullWidth" />
        <Grid sx={{ height: 'auto', width: '100%', p: 0 }}>
          <Grid display='flex' justifyContent='space-between' width='100%' alignItems='center'>
            <Tabs value={value} onChange={handleChange} sx={{ bgcolor: 'white', padding: '0.5rem 1rem', backgroundColor: 'common.black' }} >
                {renderTabs(items)}
            </Tabs>
            <Grid width='18rem' justifyContent={usersPermissions?.["viewRedactedResults"] ? 'space-between' : 'flex-end'} display='flex' gap='1.5rem'>
              {usersPermissions?.["viewRedactedResults"] &&
                <TextField
                  sx={{ width: '14rem' }}
                  select
                  label="Select View"
                  defaultValue={'Original'}
                  value={view}
                  disabled={(usersPermissions?.["viewRedactedResults"] && (notesJSON.length !== 0 || createdRedaction)) && !editRedactedMode ? false : true}
                  SelectProps={{
                    sx: {
                      fontFamily: 'Montserrat',
                      '&.MuiMenu-paper': {
                        backgroundColor: 'red'
                      }
                    },
                  }}
                  onChange={changeView}
                >
                  <MenuItem value={'Original'}>Original</MenuItem>
                  <MenuItem value={'Redacted'}>Redacted</MenuItem>
                  <MenuItem value={'Original & Redacted'}>Original & Redacted</MenuItem>

                </TextField>
              }
              <Grid item display='flex' justifyContent='center' alignItems='center'>
                <Button size='small' endIcon={isVisible ? <DownArrow style={{ color: '#8A72FF' }} /> : <UpArrow style={{ color: '#8A72FF' }} />}
                  disableRipple onClick={toggleDataVisibility} sx={{ padding: '4px 5px', height: '30.75px', alignItems: 'center' }}>
                  {rapSheetVisible ? 'HIDE' : 'SHOW'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {items[value].children}
        </Grid>
      </Container>
    </>
  );
};
export default Results;
