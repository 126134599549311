import { CognitoUser, OriObject, SoriObject, User } from "@apollo/user-wrapper"

export enum AttributeNames {
    CUSTOM_ORI = "custom:ori",
    CUSTOM_ORI_LONG = "custom:ori-long",
    SUB = "sub",
    EMAIL_VERIFIED = "email_verified",
    CUSTOM_FIRST_TIME_SIGNED_IN = "custom:firstTimeSignedIn",
    CUSTOM_SORI = "custom:secondary-ori",
    GIVEN_NAME = "given_name",
    FAMILY_NAME = "family_name",
    EMAIL = "email",
}

export enum GroupNames {
    CREATION_DATE = "CreationDate",
    DESCRIPTION = "Description",
    GROUP_NAME = "GroupName"
}

export type UserAttribute = {
    Name: AttributeNames
    Value: string
}

export type UserGroup = {
    Name: GroupNames
    Value: string
}

export type UserAttributes = UserAttribute[]
export type userGroup = UserGroup[]

export interface UserRow {
    key: string
    fullName?: string
    loginName?: string
    securityLevel?: string
    status?: string
    emailAddress?: string
    primaryOri?: string[]
    secondaryOri?: string[]
    reasonFingerprinted?: string[]
    lastModified?: string | undefined
    oriRfpGroups?: Array<OriGroup>
    loginStatus?: string
    user?: {
        userId: string,
        jsonData: CognitoUser
    }
}

export enum UserStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive'
}

export interface OriGroup {
    index: number,
    primaryOri?: {
        assigned: boolean
        field: string
        value: string
    }
    secondaryOri?: {
        assigned: boolean
        field: string
        value: string
    }
    rfp: {
        assigned: boolean
        field: string
        value: string
    }
}

export enum userManagementColumnFilters {
    SecurityLevel = 'securityLevel',
    Status = 'status',
    PrimaryOri = 'primaryOri',
    ReasonFingerprinted = 'reasonFingerprinted'
}